import Amplify from "aws-amplify";
import AWS from "aws-sdk";

AWS.config.region = "eu-west-1";

const OAUTH_DOMAIN =
  process.env
    .REACT_APP_service_cd_team_pipelines_team_dashboard_sso_domain_name;
const COGNITO_IPD_ID =
  process.env
    .REACT_APP_service_cd_team_pipelines_team_dashboard_identity_pool_id;
const COGNITO_USERPOOL_WEBCLIENT_ID =
  process.env
    .REACT_APP_service_cd_team_pipelines_team_dashboard_user_pool_client_id;
const COGNITO_USERPOOL_ID =
  process.env.REACT_APP_service_cd_team_pipelines_team_dashboard_user_pool_id;

const config = {
  Auth: {
    region: AWS.config.region,
    identityPoolId: COGNITO_IPD_ID,
    userPoolId: COGNITO_USERPOOL_ID,
    userPoolWebClientId: COGNITO_USERPOOL_WEBCLIENT_ID,
    oauth: {
      domain: OAUTH_DOMAIN,
      scope: ["email", "profile", "openid", "aws.cognito.signin.user.admin"],
      redirectSignIn: `${window.location.protocol}//${window.location.host}/`,
      redirectSignOut: `${window.location.protocol}//${window.location.host}/`,
      responseType: "code",
    },
  },
};

console.log("Configuring amplify", config);

Amplify.configure(config);
