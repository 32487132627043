// @flow

import { Auth } from "@aws-amplify/auth";
import AWS from "aws-sdk";

const getS3 = async (): Promise<*> => {
  const creds = Auth.essentialCredentials(await Auth.currentCredentials());
  return new AWS.S3({ ...creds, region: "eu-west-1", maxRetries: 3 });
};

export default getS3;
