// @flow

import React, { PureComponent } from "react";
import {
  Text,
  Stack,
  PrimaryButton,
  MessageBar,
  MessageBarType,
  Dropdown,
} from "@fluentui/react";
import AWS from "aws-sdk";
import { Auth } from "@aws-amplify/auth";
import { AppContext } from "../context/AppContext";

const ACCOUNT_CONTENT_DEV_ID = "573434510680";

type Props = {|
  +accounts: {| [accountId: string]: string |},
|};
type State = {|
  +success: string | false,
  +error: string | false,
  +warning: string | false,
  +inProgress: boolean,
  env: string,
|};

const functionNames = [
  "hi-pull-semantics-spreadsheet",
  "hi-pull-journeys-spreadsheet",
];

export default class ContentsPull extends PureComponent<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      success: false,
      error: false,
      warning: false,
      inProgress: false,
    };
  }
  _commitChange = async ({ key }: { key: string }) => {
    this.setState(() => ({
      inProgress: true,
    }));

    const creds = Auth.essentialCredentials(await Auth.currentCredentials());
    const lambda = new AWS.Lambda({ ...creds });

    const invokeOpts = {
      Payload: JSON.stringify({ key }),
      InvocationType: "RequestResponse",
    };

    await lambda
      .invoke({
        ...invokeOpts,
        FunctionName: `arn:aws:lambda:eu-west-1:${ACCOUNT_CONTENT_DEV_ID}:function:hi-pull-commit-changes`,
      })
      .promise();

    alert("Save completed; Pull again to verify.");

    this.setState(() => ({
      inProgress: false,
    }));
  };

  _saveUpdate = async () => {
    const { inProgress } = this.state;
    const { userStateEnv } = this.context;

    this.setState(() => ({
      error: false,
      success: false,
      warning: false,
      inProgress: true,
    }));

    const creds = Auth.essentialCredentials(await Auth.currentCredentials());
    const lambda = new AWS.Lambda({
      ...creds,
      httpOptions: { timeout: 600000 },
    });

    const invokeOpts = {
      Payload: JSON.stringify({
        source: "team-deployments",
        env: userStateEnv,
      }),
      InvocationType: "RequestResponse",
    };

    const functionsInvocations = functionNames.map((functionName) =>
      lambda
        .invoke({
          ...invokeOpts,
          FunctionName: `arn:aws:lambda:eu-west-1:${ACCOUNT_CONTENT_DEV_ID}:function:${functionName}`,
        })
        .promise()
        .then((r) => JSON.parse(r.Payload.toString("utf-8")))
    );

    const formatDiff = (strDiff, key) => {
      if (!strDiff) {
        return <li>No changes</li>;
      }

      const lines = strDiff.split("\n");

      const lis = lines.map((line, i, lines) => {
        if (line.startsWith("+ ")) {
          return (
            <li style={{ color: "green", whiteSpace: "pre" }}>
              {line}
              <br />
            </li>
          );
        }
        if (line.startsWith("- ")) {
          return (
            <li style={{ color: "red", whiteSpace: "pre" }}>
              {line}
              <br />
            </li>
          );
        }
        return (
          <li style={{ color: "gray", whiteSpace: "pre" }}>
            {line}
            <br />
          </li>
        );
      });

      lis.push(
        <li>
          <PrimaryButton
            style={{ backgroundColor: "#cb0000" }}
            iconProps={{ iconName: "DatabaseSync" }}
            onClick={() => this._commitChange({ key })}
            text="Approve and release"
            disabled={inProgress}
          />
        </li>
      );

      return lis;
    };

    const invokeResults = await Promise.allSettled(functionsInvocations);

    if (invokeResults.some(({ value }) => value.errorMessage)) {
      alert(
        `Pull content error:\n${invokeResults
          .filter(({ value }) => value.errorMessage)
          .map(({ value }) => `• ${value.errorMessage}`)
          .join("\n\n")}`
      );
      return;
    }

    const resultItems = invokeResults.map((result, index) => (
      <React.Fragment>
        <Text style={{ fontWeight: "bold" }}>
          Changes for {functionNames[index]}
        </Text>

        <ul style={{ fontFamily: "monospace", listStyleType: "none" }}>
          {result && result.value
            ? formatDiff(result.value.diff, result.value.key)
            : "UNKOWN_CHANGE_EMPTY_RESULT"}
        </ul>

        {result &&
          result.status !== "fulfilled" &&
          `An error occurred while processing this request: ${result.reason.name}`}
      </React.Fragment>
    ));

    const containsError = invokeResults.some((r) => r.status !== "fulfilled");

    this.setState(() => ({
      success: !containsError && resultItems,
      warning: containsError && resultItems,
      error: false,
      inProgress: false,
    }));
  };

  _handleEnvironmentChoice = (
    e: SyntheticEvent<HTMLInputElement>,
    value: {| key: string, text: string |}
  ) => {
    this.context.setUserStateEnv(value.text);
  };

  render() {
    const { accounts } = this.props;
    const { success, error, warning, inProgress } = this.state;
    const { userStateEnv } = this.context;

    const environmentOptions = Object.entries(accounts).map(([text, key]) => ({
      key,
      text,
    }));

    const selectedKey = accounts[this.context.userStateEnv];

    return (
      <Stack tokens={{ childrenGap: 15 }}>
        <Text variant="large">Pull updated content from the spreadsheets</Text>

        <Text variant="small">
          The following content will be synced:{" "}
          {functionNames.map((f) => f.replace("hi-pull-", "")).join(", ")}
        </Text>

        <Stack
          horizontal
          tokens={{ childrenGap: 15 }}
          style={{ alignItems: "flex-end" }}
        >
          <Dropdown
            required
            label="Target Account"
            placeholder="Select the Environment"
            onChange={this._handleEnvironmentChoice}
            options={environmentOptions}
            selectedKey={selectedKey}
          />

          <PrimaryButton
            iconProps={{ iconName: "DatabaseSync" }}
            onClick={this._saveUpdate}
            text="Preview changes"
            disabled={!userStateEnv || inProgress}
          />
        </Stack>

        <Stack>
          {success &&
            success.map((s, i) => (
              <MessageBar
                key={i}
                messageBarType={MessageBarType.info}
                isMultiline
                style={{ marginBottom: 5 }}
              >
                {s}
              </MessageBar>
            ))}

          {warning &&
            warning.map((w, i) => (
              <MessageBar
                key={i}
                messageBarType={MessageBarType.warning}
                isMultiline
                style={{ marginBottom: 5 }}
              >
                {w}
              </MessageBar>
            ))}

          {error && (
            <MessageBar messageBarType={MessageBarType.error} isMultiline>
              {error}
            </MessageBar>
          )}
        </Stack>
      </Stack>
    );
  }
}

ContentsPull.contextType = AppContext;
