// @flow

import React, { PureComponent } from "react";
import {
  SelectionMode,
  Link,
  Text,
  ShimmeredDetailsList,
  Selection,
  Stack,
  Icon,
  IconButton,
  CommandBarButton,
  Spinner,
  SpinnerSize,
  MessageBar,
  MessageBarType,
} from "@fluentui/react";
import moment from "moment";
import AWS from "aws-sdk";
import { Auth } from "@aws-amplify/auth";

import type { BuildInfo } from "../types";

import ExecuteDeployPanel from "./ExecuteDeployPanel";
import LogsPanel from "./LogsPanel";

const BUILDS_SHOWN_COUNT = 5;

type Props = {|
  +projectName: string,
  +accounts: {| [accountId: string]: string |},
|};

type State = {|
  +buildsInfo: Array<BuildInfo>,
  +executeDeployPanelOpened: boolean,
  +deployRequestParams: {|
    microserviceName: string,
    developerName: string,
    branchName: ?string,
    targetAccount?: string,
  |},
  +notShownBuildsCount: number,
  +error: string | false,
|};

export default class BuildsStatus extends PureComponent<Props, State> {
  _selection: Selection;
  _refreshInterval: IntervalID;

  state: State = {
    buildsInfo: [],
    executeDeployPanelOpened: false,
    deployRequestParams: {
      microserviceName: "",
      developerName: "",
      branchName: null,
      targetAccount: undefined,
    },
    notShownBuildsCount: 0,
    error: false,
  };

  constructor(props: Props) {
    super(props);

    this._selection = new Selection({
      onSelectionChanged: () => {
        this.forceUpdate();
      },
    });
  }

  async componentDidMount() {
    await this.fetchDetails();
    // this._refreshInterval = setInterval(() => this.fetchDetails(), 5 * 1000);
  }

  async componentDidUpdate(prevProps: Props) {
    if (prevProps.projectName !== this.props.projectName) {
      await this.fetchDetails();
    }
  }

  componentWillUnmount() {
    clearInterval(this._refreshInterval);
  }

  getTargetAccount = (buildInfo: BuildInfo): string | void => {
    return buildInfo.environment.environmentVariables.find(
      (v) => v.name === "TARGET_ACCOUNT_ID"
    )?.value;
  };

  getDeveloperNameFromAccountId = (accountId: ?string) => {
    const { accounts } = this.props;
    const developerName = Object.keys(accounts).find(
      (devName) => accountId === accounts[devName]
    );
    return developerName;
  };

  static getDerivedStateFromProps(props: Props, state: State) {
    const { projectName } = props;

    if (!projectName) {
      return { ...state };
    }

    let tokens;
    if (projectName.startsWith("devbuild")) {
      tokens = /^devbuild-(.*)-(\w+)$/.exec(projectName);
    } else {
      tokens = /^pipeline-deployer-(.*)$/.exec(projectName);
    }

    if (!tokens) {
      return { ...state };
    }

    const [, microserviceName, developerName] = tokens;
    return {
      ...state,
      deployRequestParams: {
        ...state.deployRequestParams,
        microserviceName,
        developerName,
      },
    };
  }

  async fetchDetails() {
    this.setState((state) => ({
      ...state,
      buildsInfo: [],
      error: false,
      notShownBuildsCount: 0,
    }));

    const { projectName } = this.props;

    const creds = Auth.essentialCredentials(await Auth.currentCredentials());
    const cb = new AWS.CodeBuild({ ...creds });

    try {
      const buildIds = (
        await cb.listBuildsForProject({ projectName }).promise()
      ).ids.slice(0, BUILDS_SHOWN_COUNT);
      const buildsInfo = (await cb.batchGetBuilds({ ids: buildIds }).promise())
        .builds;

      this.setState((state) => ({
        ...state,
        buildsInfo,
        notShownBuildsCount: Math.max(0, buildIds.length - BUILDS_SHOWN_COUNT),
      }));
    } catch (e) {
      console.error("Cannot fetch builds list", e);
      this.setState((state) => ({
        error: `Cannot fetch build details: "${e.message}"`,
      }));
    }
  }

  _renderItemColumn = (item: BuildInfo, index: number, column: $FlowFixMe) => {
    const fieldContent = item[column.fieldName];

    switch (column.key) {
      case "startTime":
        return <Text>{moment(fieldContent).format("lll")}</Text>;

      case "buildNumber":
        return <Text># {fieldContent}</Text>;

      case "sourceVersion": {
        const { sourceVersion } = item;
        if (!sourceVersion) {
          return null;
        }
        const makePRWebUrl = `${
          item.source?.location?.replace(".git", "") || ""
        }/${sourceVersion.replace("pr", "pull")}`;
        return (
          <Link target="_blank" href={makePRWebUrl}>
            {sourceVersion}
          </Link>
        );
      }

      case "buildStatus":
        if (!item.buildComplete) {
          return (
            <Spinner
              size={SpinnerSize.xSmall}
              label="In progress..."
              labelPosition="right"
            />
          );
        }

        switch (item.buildStatus) {
          case "FAILED":
            return (
              <Icon iconName="StatusErrorFull" style={{ color: "#cb0000" }} />
            );
          case "SUCCEEDED":
            return (
              <Icon iconName="SkypeCircleCheck" style={{ color: "#00cb00" }} />
            );
          case "STOPPED":
            return (
              <Icon iconName="StatusErrorFull" style={{ color: "#cbcbcb" }} />
            );
          default:
            return (
              <Link target="_blank" href={item.logs.deepLink}>
                {fieldContent}
              </Link>
            );
        }

      case "targetAccount":
        return (
          <Text variant="small">
            {this.getDeveloperNameFromAccountId(this.getTargetAccount(item))}
          </Text>
        );

      case "actions":
        return (
          <>
            <CommandBarButton
              iconProps={{ iconName: "PlaybackRate1x" }}
              text="Re-deploy"
              onClick={this.showDeploymentPanelFactory({ buildInfo: item })}
            />
            <CommandBarButton
              iconProps={{ iconName: "GroupList" }}
              text="Logs"
              href={item.logs.deepLink}
              target="_blank"
            />
          </>
        );
      default:
        return <span>{fieldContent}</span>;
    }
  };

  _getColumns = () => {
    return [
      {
        key: "startTime",
        fieldName: "startTime",
        name: "Start Time",
        minWidth: 200,
        maxWidth: 300,
      },
      {
        key: "sourceVersion",
        fieldName: "sourceVersion",
        name: "Git Source Version",
        maxWidth: 300,
      },
      {
        key: "targetAccount",
        fieldName: "environment",
        name: "Target Account",
        maxWidth: 300,
      },
      {
        key: "buildNumber",
        fieldName: "buildNumber",
        name: "Build",
        maxWidth: 300,
      },
      {
        key: "buildStatus", // also check buildComplete
        fieldName: "buildStatus",
        name: "Status",
        maxWidth: 300,
      },
      {
        key: "actions",
        name: "Actions",
        maxWidth: 300,
      },
    ];
  };

  hideDeploymentPanel = () => {
    this.setState((state) => ({
      executeDeployPanelOpened: false,
      deployRequestParams: {
        microserviceName: "",
        developerName: "",
        branchName: null,
        targetAccount: undefined,
      },
    }));
  };

  showDeploymentPanelFactory = ({
    buildInfo,
  }: {|
    +buildInfo: BuildInfo,
  |}) => () => {
    this.setState((state) => ({
      executeDeployPanelOpened: true,
      deployRequestParams: {
        ...state.deployRequestParams,
        branchName: buildInfo.sourceVersion,
        targetAccount: this.getTargetAccount(buildInfo),
      },
    }));
  };

  render() {
    const { projectName, accounts } = this.props;
    const {
      buildsInfo,
      executeDeployPanelOpened,
      deployRequestParams,
      notShownBuildsCount,
      error,
    } = this.state;

    const selectedBuild = this._selection.getSelection()[0];

    const onlyWithGitSource = (buildInfo) =>
      !buildInfo.sourceVersion?.startsWith("arn:aws:s3:::");

    return (
      <Stack tokens={{ padding: 20, childrenGap: 0 }}>
        <Stack horizontal>
          <Text>
            Details for <code>{projectName}</code>
          </Text>
          <IconButton
            iconProps={{ iconName: "Refresh" }}
            onClick={() => this.fetchDetails()}
          />
        </Stack>

        {error && (
          <Stack tokens={{ childrenGap: 15 }}>
            <MessageBar messageBarType={MessageBarType.warning} isMultiline>
              {error}
              <br />
              If the project does not exist, probably it is still being created.
              If the error persists for more than 5 minutes, try to close and
              re-open the PR associated to it.
            </MessageBar>
          </Stack>
        )}

        {!error && (
          <Stack tokens={{ childrenGap: 15 }}>
            <ShimmeredDetailsList
              selectionMode={SelectionMode.single}
              selection={this._selection}
              setKey="buildNumber"
              items={buildsInfo.filter(onlyWithGitSource)}
              columns={this._getColumns()}
              onRenderItemColumn={this._renderItemColumn}
              enableShimmer={buildsInfo.length === 0}
              selectionPreservedOnEmptyClick
              shimmerLines={5}
            />
            {notShownBuildsCount > 0 && (
              <Text variant="small">
                {notShownBuildsCount} older builds not shown here
              </Text>
            )}
          </Stack>
        )}

        <ExecuteDeployPanel
          isOpen={executeDeployPanelOpened}
          onClose={this.hideDeploymentPanel}
          accounts={accounts}
          {...deployRequestParams}
        />

        {selectedBuild && <LogsPanel buildInfo={selectedBuild} />}
      </Stack>
    );
  }
}
