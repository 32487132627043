// @flow

import React, { PureComponent } from "react";
import {
  Text,
  Stack,
  PrimaryButton,
  MessageBar,
  MessageBarType,
  DefaultButton,
  Dropdown,
  TextField,
} from "@fluentui/react";
import AWS from "aws-sdk";
import { Auth } from "@aws-amplify/auth";

import accounts from "../accounts";
import { AppContext } from "../context/AppContext";

const getJourneyName = (uj) => (typeof uj === "string" ? uj : uj.journeyName);

export default class CompanyStateEditor extends PureComponent<{||}, {||}> {
  fetchData = async () => {
    const { companyId, userStateEnv } = this.context;

    let errorMessage = "";
    if (!companyId) {
      errorMessage = `${errorMessage}Company is required!`;
    }

    if (!userStateEnv) {
      errorMessage = `${errorMessage}\nEnvironment is required!`;
    }

    if (errorMessage) {
      this.context.setCompanyStateStatus({
        error: errorMessage,
      });
      return;
    }

    const environment = accounts.find(({ key }) => key === userStateEnv);

    const creds = Auth.essentialCredentials(await Auth.currentCredentials());
    const s3 = new AWS.S3({ ...creds });

    try {
      const companyStateResult = await s3
        .getObject({
          Bucket: environment.contentStorageBucketName,
          Key: `${userStateEnv}/templates/${companyId}.json`,
        })
        .promise();

      const companyState = JSON.stringify(
        JSON.parse(companyStateResult.Body.toString("utf-8")),
        null,
        2
      );

      this.context.setCompanyStateStatus({
        error: false,
        success: false,
        warning: false,
      });
      this.context.setCompanyState(companyState);
    } catch (e) {
      this.context.setCompanyStateStatus({
        error: false,
        success: false,
        warning: `Company State does not exist for this companyId in this environment. Error: ${e.message}`,
      });
      this.context.setCompanyState("");
    }

    try {
      const lambda = new AWS.Lambda({ ...creds });

      const { Payload } = await lambda
        .invoke({
          FunctionName: `arn:aws:lambda:${AWS.config.region}:${
            this.props.accounts[environment.key]
          }:function:get-user-journeys`,
          Payload: JSON.stringify({
            cid: companyId,
          }),
        })
        .promise();

      const parsedPayload = JSON.parse(Payload);

      if (parsedPayload.errorMessage) {
        throw new Error(parsedPayload.errorMessage);
      }
      this.context.setJourneys(parsedPayload);
    } catch (e) {
      this.context.setCompanyStateStatus({
        error: `Error while fetching journeys: ${e.message}`,
      });
    }
  };

  _fetchState = async () => {
    await this.fetchData();
  };

  _saveUpdate = async () => {
    const { companyId, companyState, userStateEnv } = this.context;
    const environment = accounts.find(({ key }) => key === userStateEnv);

    this.context.setCompanyStateStatus({
      error: false,
      success: false,
      warning: false,
    });

    const creds = Auth.essentialCredentials(await Auth.currentCredentials());
    const s3 = new AWS.S3({ ...creds });

    try {
      const companyStateResult = await s3
        .putObject({
          Bucket: environment.contentStorageBucketName,
          Key: `${userStateEnv}/templates/${companyId}.json`,
          Body: JSON.stringify(JSON.parse(companyState)), // remove newlines
          ACL: "bucket-owner-full-control",
        })
        .promise();

      this.context.setCompanyStateStatus({
        success: `Company State saved successfully: ${companyStateResult.ETag}`,
        error: false,
        warning: false,
      });
    } catch (e) {
      this.context.setCompanyStateStatus({
        error: `Error saving updated Company State: ${e.message}`,
        success: false,
        warning: false,
      });
    }
  };

  _handleCompanyStateChange = (
    e: SyntheticEvent<HTMLInputElement>,
    value: string
  ) => {
    this.context.setCompanyStateStatus({ warning: false });

    try {
      JSON.parse(value);
      this.context.setCompanyStateStatus({ warning: false });
    } catch (e) {
      this.context.setCompanyStateStatus({
        warning: `${e.message}`,
      });
    }

    if (value.includes("deviceId")) {
      this.context.setCompanyStateStatus({
        warning: "Company State shall not include deviceId",
      });
    }

    this.context.setCompanyState(value);
  };

  _handleEnvironmentChoice = (
    e: SyntheticEvent<HTMLInputElement>,
    {
      key,
    }: {|
      key: string,
      text: string,
      userMetaBucketName: string,
    |}
  ) => {
    this.context.setUserStateEnv(key);
  };

  _handleCompanyIdChange = (
    e: SyntheticEvent<HTMLInputElement>,
    value: string
  ) => {
    const companyId = value
      .trim()
      .toLowerCase()
      .replace(/[^a-z0-9-]+/, "");
    this.context.setCompanyId(companyId);
  };

  _handleAvailableUJChange = (
    e: SyntheticEvent<HTMLInputElement>,
    item: $FlowFixMe
  ) => {
    try {
      const tempCompanyState = JSON.parse(this.context.companyState);

      if (!tempCompanyState?.statePatches?.availableJourneys) {
        tempCompanyState.statePatches.availableJourneys = item.selected
          ? [item.key]
          : [];
        this.context.setCompanyState(JSON.stringify(tempCompanyState, null, 2));
        return;
      }

      if (!item) {
        return;
      }

      tempCompanyState.statePatches.availableJourneys = item.selected
        ? [...tempCompanyState.statePatches.availableJourneys, item.key]
        : tempCompanyState.statePatches.availableJourneys.filter(
            (key) => key !== item.key
          );
      this.context.setCompanyState(JSON.stringify(tempCompanyState, null, 2));
    } catch (err) {
      this.context.setUserStateStatus({ warning: `${err.message}` });
    }
  };

  _handleUJChange = (e: SyntheticEvent<HTMLInputElement>, item: $FlowFixMe) => {
    try {
      const tempCompanyState = JSON.parse(this.context.companyState);
      tempCompanyState.statePatches.currentJourney = item.key || "";
      this.context.setCompanyState(JSON.stringify(tempCompanyState, null, 2));
      this.context.setUserJourneys(
        tempCompanyState?.statePatches?.currentJourney
          ? [tempCompanyState.statePatches.currentJourney]
          : []
      );
    } catch (err) {
      this.context.setUserStateStatus({ warning: `${err.message}` });
    }
  };

  render() {
    const {
      companyId,
      companyState,
      userStateEnv,
      companyStateStatus,
      journeys,
    } = this.context;
    const { success, error, warning } = companyStateStatus;

    let canSave = false;
    let parsedState = {};

    try {
      parsedState = JSON.parse(companyState);
      canSave =
        parsedState.templateName &&
        parsedState.companyId &&
        typeof parsedState.statePatches === "object";
    } catch (e) {}

    return (
      <Stack tokens={{ childrenGap: 15 }}>
        <Text variant="large">
          Update a Company State{" "}
          {companyId ? (
            <span>
              for <code>{companyId}</code>
            </span>
          ) : (
            ""
          )}
        </Text>

        <Stack
          horizontal
          tokens={{ childrenGap: 15 }}
          style={{ alignItems: "flex-end" }}
        >
          <Dropdown
            required
            label="Target Account"
            placeholder="Select the Environment"
            onChange={this._handleEnvironmentChoice}
            options={accounts}
            selectedKey={userStateEnv}
          />

          <TextField
            required
            label="Company Id"
            placeholder="Enter a companyId..."
            value={companyId}
            onChange={this._handleCompanyIdChange}
            style={{ width: 350, fontFamily: "monospace" }}
          />

          <DefaultButton
            iconProps={{ iconName: "CloudImportExport" }}
            onClick={this._fetchState}
            text="Load"
          />

          <PrimaryButton
            style={{ backgroundColor: "#cb0000" }}
            iconProps={{ iconName: "BoxCheckmarkSolid" }}
            onClick={this._saveUpdate}
            text="Save"
            disabled={warning || !canSave}
          />

          <Stack>
            {success && (
              <MessageBar messageBarType={MessageBarType.success} isMultiline>
                {success}
              </MessageBar>
            )}

            {error && (
              <MessageBar messageBarType={MessageBarType.error} isMultiline>
                {error}
              </MessageBar>
            )}

            {warning && (
              <MessageBar messageBarType={MessageBarType.warning} isMultiline>
                {warning}
              </MessageBar>
            )}

            {!canSave && (
              <MessageBar
                messageBarType={MessageBarType.severeWarning}
                isMultiline
              >
                One or more required properties are missing in the Company
                State.
              </MessageBar>
            )}
          </Stack>
        </Stack>

        <Stack
          horizontal
          tokens={{ childrenGap: 15 }}
          style={{ alignItems: "flex-end" }}
        >
          <Dropdown
            placeholder="Select journey..."
            label="Select journey"
            onChange={this._handleUJChange}
            defaultSelectedKey={parsedState.statePatches?.currentJourney}
            options={
              journeys
                ? Object.keys(journeys).map((key) => {
                    const journey = journeys[key];
                    return {
                      key: journey.journeyName,
                      text: journey.journeyName,
                    };
                  })
                : []
            }
            styles={{ dropdown: { width: 250 } }}
          />
          <Dropdown
            placeholder="Select available journeys..."
            label="Select available journeys"
            onChange={this._handleAvailableUJChange}
            multiSelect
            selectedKeys={(
              parsedState.statePatches?.availableJourneys || []
            ).map(getJourneyName)}
            options={
              journeys
                ? Object.keys(journeys).map((key) => {
                    const journey = journeys[key];
                    return {
                      key: journey.journeyName,
                      text: journey.journeyName,
                    };
                  })
                : []
            }
            styles={{ dropdown: { width: 250 } }}
          />
        </Stack>

        <TextField
          required
          label="Company State"
          placeholder="Enter a companyId..."
          value={companyState}
          onChange={this._handleCompanyStateChange}
          style={{ width: 250, fontFamily: "monospace" }}
          multiline
          autoAdjustHeight
        />
      </Stack>
    );
  }
}

CompanyStateEditor.contextType = AppContext;
