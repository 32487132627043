// @flow

import React, { PureComponent } from "react";
import {
  Stack,
  ShimmeredDetailsList,
  SelectionMode,
  Text,
  Link,
  Selection,
  Icon,
  Spinner,
  SpinnerSize,
} from "@fluentui/react";
import moment from "moment";
import PipelineStatus from "./PipelineStatus";

declare type Status = "Succeeded" | "Failed" | "InProgress" | "Unknown";

declare type ActionState = {|
  actionName: string,
  currentRevision?: {| revisionId: string |},
  entityUrl: string,
  latestExecution?: {|
    externalExecutionId?: string,
    lastStatusChange: string,
    status: Status,
    summary?: string,
  |},
  revisionUrl: string,
|};

declare type StageState = {|
  actionStates?: Array<ActionState>,
  inboundTransitionState: {| enabled: boolean |},
  latestExecution?: {|
    pipelineExecutionId: string,
    status: Status,
  |},
  stageName: string,
|};

declare type PipelineState = {|
  created: string,
  pipelineName: string,
  pipelineVersion: number,
  stageStates?: Array<?StageState>,
  updated: string,
|};

type Props = {|
  +microservices: $ReadOnlyArray<{|
    +repo: string,
    +name: string,
    +updatedOn: string,
  |}>,
  +pipelineStates: {| [key: string]: ?PipelineState |},
|};

export default class ReleasesList extends PureComponent<Props, State> {
  _interval: IntervalID;
  _selection: Selection = null;

  constructor(props: Props) {
    super(props);

    this._selection = new Selection({
      onSelectionChanged: () => this.forceUpdate(),
    });
  }

  _getPipelineName = (microservice: $FlowFixMe) => {
    return `deployment-${microservice.name}`;
  };

  _getStatusIcon = (status: Status) => {
    const iconStyle = { height: 30, width: 30, display: "inline-flex" };
    if (status === "Failed") {
      return (
        <Icon
          iconName="BoxMultiplySolid"
          style={{ ...iconStyle, color: "red" }}
        />
      );
    }
    if (status === "Succeeded") {
      return (
        <Icon
          iconName="BoxCheckmarkSolid"
          style={{ ...iconStyle, color: "green" }}
        />
      );
    }
    if (status === "InProgress") {
      return <Spinner style={iconStyle} size={SpinnerSize.medium} />;
    }
    if (status === "Unknown") {
      return (
        <Icon iconName="UnknownSolid" style={{ ...iconStyle, color: "gray" }} />
      );
    }
  };

  _isApprovalPending = (stageStates: StageState) => {
    if (!stageStates) {
      return false;
    }

    const pendingManualAction = stageStates.actionStates?.some(
      (actionState) =>
        actionState.actionName.includes("Approv") &&
        actionState.latestExecution?.status === "InProgress"
    );
    return Boolean(pendingManualAction);
  };

  _renderItemColumn = (item: $FlowFixMe, index: number, column: $FlowFixMe) => {
    const { pipelineStates } = this.props;

    const state = pipelineStates[item.name];
    const sourceAction = state?.stageStates?.[0]?.actionStates?.[0];
    const qaStage = state?.stageStates?.[1];
    const prodStage = state?.stageStates?.[2];
    const lastSourceExecution = sourceAction?.latestExecution;

    const fieldContent = item[column.fieldName];

    switch (column.key) {
      case "name":
        return (
          <Stack horizontal>
            <Link
              data-selection-disabled={true}
              href={`https://github.com/dtttd/${item.name}`}
              target="_blank"
            >
              {fieldContent}
            </Link>
          </Stack>
        );

      case "sourceDate":
        if (!lastSourceExecution) {
          return false;
        }
        return (
          <Stack horizontal>
            <Text>{this._getStatusIcon(lastSourceExecution.status)} </Text>
            <Text
              variant="xSmall"
              title={moment(lastSourceExecution.lastStatusChange).format("lll")}
            >
              {moment(lastSourceExecution.lastStatusChange).fromNow()}
            </Text>
          </Stack>
        );

      case "sourceVersion":
        if (!sourceAction) {
          return <Text>Infrastructure creation in progress...</Text>;
        }

        const prId = /#(\d+)/.exec(lastSourceExecution?.summary || "")?.[1];

        return (
          <Stack>
            {prId && (
              <React.Fragment>
                <Link
                  href={sourceAction.revisionUrl.replace(
                    /commit\/.*/,
                    `pull/${prId}`
                  )}
                  target="_blank"
                >
                  <Icon iconName="OpenInNewWindow" /> pr/<strong>{prId}</strong>
                </Link>{" "}
              </React.Fragment>
            )}
            <Link href={sourceAction.revisionUrl} target="_blank">
              <Icon iconName="OpenInNewWindow" />{" "}
              {sourceAction.currentRevision?.revisionId.substr(0, 8)}
            </Link>{" "}
          </Stack>
        );

      case "clubhouseStory": {
        if (!sourceAction || !lastSourceExecution?.summary) {
          return (
            <span role="img" aria-label="Pile of Poo">
              💩
            </span>
          );
        }

        const [, prefix, storyId] =
          /(ch|sc-)(\d+)/.exec(lastSourceExecution.summary) || [];
        if (!storyId) {
          return (
            <span role="img" aria-label="Pile of Poo">
              💩
            </span>
          );
        }

        return (
          <Stack>
            <Link
              style={{ color: "#cb0000" }}
              href={`https://app.clubhouse.io/dtttd/story/${storyId}`}
              target="_blank"
            >
              <Icon iconName="OpenInNewWindow" /> {prefix}
              <strong>{storyId}</strong>
            </Link>

            {lastSourceExecution.summary.split("\n") && (
              <Text variant="small">
                {lastSourceExecution.summary.split("\n")[2]}
              </Text>
            )}
          </Stack>
        );
      }

      case "qa": {
        if (!qaStage) {
          return false;
        }

        const latestActionExecution =
          qaStage.actionStates?.[qaStage.actionStates.length - 1]
            .latestExecution;

        const summary = latestActionExecution?.summary;
        const lastExecution = latestActionExecution?.lastStatusChange;
        const statusText = qaStage.latestExecution?.status || "Unknown";

        return (
          <Stack horizontal>
            <Text title={summary}>
              {this._isApprovalPending(qaStage) ? (
                <Icon
                  title="Manual approval is pending"
                  iconName="AlertSolid"
                  style={{ color: "orange" }}
                  styles={{ root: { fontSize: "1.5em" } }}
                />
              ) : (
                this._getStatusIcon(statusText)
              )}
            </Text>
            {lastExecution && (
              <Text
                variant="xSmall"
                title={moment(lastExecution).format("lll")}
              >
                {moment(lastExecution).fromNow()}
              </Text>
            )}
          </Stack>
        );
      }

      case "prod": {
        if (!prodStage) {
          return false;
        }
        const [latestAction] = prodStage.actionStates?.reverse() || [{}];
        const latestActionExecution = latestAction.latestExecution;

        const summary = latestActionExecution?.summary;
        const lastExecution = latestActionExecution?.lastStatusChange;
        const statusText = prodStage.latestExecution?.status || "Unknown";

        return (
          <Stack horizontal>
            <Text title={summary}>
              {this._isApprovalPending(prodStage) ? (
                <Icon
                  title="Manual approval is pending"
                  iconName="AlertSolid"
                  style={{ color: "orange" }}
                  styles={{ root: { fontSize: "1.5em" } }}
                />
              ) : (
                this._getStatusIcon(statusText)
              )}
            </Text>
            {lastExecution && (
              <Text
                variant="xSmall"
                title={moment(lastExecution).format("lll")}
              >
                {moment(lastExecution).fromNow()}
              </Text>
            )}
          </Stack>
        );
      }

      default:
        return <span>{fieldContent}</span>;
    }
  };

  _getColumns = () => {
    return [
      {
        key: "name",
        name: "Microservice",
        fieldName: "name",
        minWidth: 200,
        maxWidth: 200,
        isResizable: false,
      },
      {
        key: "sourceVersion",
        name: "Most Recent Source Version",
        minWidth: 100,
        maxWidth: 100,
        isResizable: false,
      },
      {
        key: "clubhouseStory",
        name: "Most Recent Story",
        minWidth: 250,
        maxWidth: 250,
        isResizable: false,
      },
      {
        key: "sourceDate",
        name: "Source",
        minWidth: 100,
        maxWidth: 100,
        isResizable: false,
      },
      {
        key: "qa",
        name: "QA",
        minWidth: 100,
        maxWidth: 100,
        isResizable: false,
      },
      {
        key: "prod",
        name: "Prod",
        minWidth: 100,
        maxWidth: 100,
        isResizable: false,
      },
    ];
  };

  _closeDetailDialog = () => {
    this._selection.setAllSelected(false);
  };

  render() {
    const { microservices } = this.props;

    const selectedItem = this._selection.getSelection()[0];

    return (
      <Stack tokens={{ childrenGap: 20 }}>
        <Stack grow={1}>
          <ShimmeredDetailsList
            items={microservices}
            selection={this._selection}
            setKey="releasesList"
            selectionMode={SelectionMode.single}
            columns={this._getColumns()}
            onRenderItemColumn={this._renderItemColumn}
            enableShimmer={!microservices || microservices.length === 0}
          />
        </Stack>

        {selectedItem && (
          <PipelineStatus
            pipelineName={this._getPipelineName(selectedItem)}
            onDismiss={this._closeDetailDialog}
            pipelineState={this.props.pipelineStates[selectedItem.name]}
          />
        )}
      </Stack>
    );
  }
}
