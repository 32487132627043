// @flow

import type { Env } from "@dtttd/types/hi";

const Production = "Prod";
const QA = "QA";
const Development = "Dev";

const environments = {
  qa: [Production, QA],
  development: [Production, QA, Development],
};

const getAcceptedEnvironments = (env: string): Env[] => environments[env] || [];

export default getAcceptedEnvironments;
