// @flow

import React, { useMemo, useCallback, useState, useEffect } from "react";
import {
  ShimmeredDetailsList,
  SelectionMode,
  ActionButton,
  Dropdown,
  MessageBar,
  MessageBarType,
  Stack,
  IconButton,
  TooltipHost,
} from "@fluentui/react";
import AWS from "aws-sdk";
import { Auth } from "@aws-amplify/auth";
import styled from "styled-components/macro";
import DeviceIdSelect from "../DeviceIdSelect";
import { useAppContext } from "../../context/AppContext";

const productOptions = [
  {
    key: null,
    text: "all",
  },
  {
    key: "hi-dashboard",
    text: "hi-dashboard",
  },
  {
    key: "stimuli",
    text: "stimuli",
  },
  {
    key: "brain",
    text: "brain",
  },
  {
    key: "teams-gym",
    text: "teams-gym",
  },
  {
    key: "",
    text: "",
  },
];

const dropdownStyles = { dropdown: { width: 300 } };

const Container = styled.div`
  max-height: calc(100vh - 200px);
  overflow: auto;
  .ms-DetailsList {
    overflow: hidden;
    width: fit-content;
  }
`;

let request;

const fetchResults = async ({ accountId, product }) => {
  const creds = Auth.essentialCredentials(await Auth.currentCredentials());
  const lambda = new AWS.Lambda({
    ...creds,
  });

  if (request) {
    request.abort();
  }

  request = lambda.invoke({
    FunctionName: `arn:aws:lambda:${AWS.config.region}:${accountId}:function:get-athena-analytics-query-results`,
    Payload: JSON.stringify({
      product,
    }),
  });

  const response = await request.promise();
  const payload = JSON.parse(response.Payload);

  if (payload.errorMessage) {
    throw new Error(payload.errorMessage);
  }

  return {
    ...payload,
    data: payload.data.map(({ date, ...props }) => ({
      ...props,
      date: new Date(date).toLocaleString(),
    })),
  };
};

type Props = {
  +accounts: {| [environment: string]: string |},
};

const columnsPlaceholder = [
  "date",
  "product",
  "action",
  "timestamp",
  "cid",
  "did",
].map((name) => ({
  key: name,
  name: name,
  fieldName: name,
  isPadded: true,
}));

const Analytics = ({ accounts }: Props) => {
  const { userStateEnv, setUserStateEnv, deviceId } = useAppContext();
  const [selectedDeviceId, setDeviceId] = useState(deviceId || null);
  const [items, setItems] = useState([]);
  const [columns, setColumns] = useState(columnsPlaceholder);
  const [csvUrl, setCsvUrl] = useState<?string>(null);
  const [selectedAccount, setSelectedAccount] = useState<?string>(
    accounts[userStateEnv || "qa"]
  );
  const [product, setProduct] = useState<?string>(productOptions[0].key);

  useEffect(() => {
    if (selectedAccount) {
      return;
    }

    setSelectedAccount(accounts.qa);
  }, [accounts, selectedAccount]);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<?string>(null);

  const fetch = useCallback(({ selectedAccount, product }) => {
    if (!selectedAccount) {
      return;
    }

    setItems(null);
    setCsvUrl(null);
    setColumns(columnsPlaceholder);
    setIsLoading(true);
    setError(null);
    fetchResults({ accountId: selectedAccount, product })
      .then(({ data, csvUrl, columns }) => {
        setIsLoading(false);
        setCsvUrl(csvUrl);
        setItems(data);
        setColumns(
          columns.map((name) => ({
            key: name,
            name: name,
            fieldName: name,
            isPadded: true,
            onRender: (column) => (
              <TooltipHost content={column[name]}>
                <div>{column[name]}</div>
              </TooltipHost>
            ),
          }))
        );
      })
      .catch((e) => {
        if (e.name === "RequestAbortedError") {
          return;
        }
        setIsLoading(false);
        setCsvUrl(null);
        setItems(null);
        setColumns(columnsPlaceholder);
        setError(e.message);
      });
  }, []);

  const reload = useCallback(() => fetch({ selectedAccount }), [
    selectedAccount,
    fetch,
  ]);

  useEffect(() => {
    fetch({ selectedAccount, product });
  }, [fetch, selectedAccount, product]);

  const handleAccountChange = useCallback(
    (e, { key, text }) => {
      setUserStateEnv(text);
      setSelectedAccount(key);
    },
    [setUserStateEnv]
  );

  const handleProductChange = useCallback((e, { key }) => {
    setProduct(key);
  }, []);

  const handleDeviceIdChange = useCallback((selectedDeviceId) => {
    setDeviceId(selectedDeviceId);
  }, []);

  const handleDownloadClick = useCallback(() => {
    window.open(csvUrl);
  }, [csvUrl]);

  const environmentOptions = useMemo<[{ key: string, text: string }]>(
    () =>
      Object.entries(accounts).map(([text, key]) => ({
        key,
        text,
      })),
    [accounts]
  );

  const filteredItems = useMemo(
    () =>
      selectedDeviceId && items
        ? items.filter(({ did }) => did?.startsWith(selectedDeviceId))
        : items,
    [items, selectedDeviceId]
  );

  return (
    <>
      <Stack
        horizontal
        tokens={{ childrenGap: 12 }}
        style={{ alignItems: "flex-end" }}
      >
        <Dropdown
          required
          label="Environment"
          placeholder="Select the environment"
          options={environmentOptions}
          onChange={handleAccountChange}
          selectedKey={selectedAccount}
          styles={dropdownStyles}
          disabled
        />
        <Dropdown
          label="Product"
          placeholder="Select the product"
          options={productOptions}
          onChange={handleProductChange}
          selectedKey={product}
          styles={dropdownStyles}
        />
        <DeviceIdSelect
          fetchedDid={deviceId}
          isRequired={false}
          onChange={handleDeviceIdChange}
          environment={
            Object.entries(accounts).find(
              ([_, accountId]) => selectedAccount === accountId
            )?.[0]
          }
        />
        <IconButton
          iconProps={{ iconName: "Refresh" }}
          onClick={reload}
          title="Refresh"
        />
        {error && (
          <MessageBar messageBarType={MessageBarType.error} isMultiline>
            {error}
          </MessageBar>
        )}
      </Stack>
      {csvUrl && (
        <ActionButton
          iconProps={{ iconName: "DownloadDocument" }}
          allowDisabledFocus
          onClick={handleDownloadClick}
        >
          Download CSV
        </ActionButton>
      )}
      {selectedAccount && (items || isLoading) && (
        <Container data-is-scrollable="true">
          <ShimmeredDetailsList
            enableShimmer={isLoading}
            items={filteredItems || []}
            columns={columns}
            selectionMode={SelectionMode.none}
          />
        </Container>
      )}
    </>
  );
};

export default Analytics;
