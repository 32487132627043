// @flow

import React, { PureComponent } from "react";
import {
  Stack,
  PrimaryButton,
  MessageBar,
  MessageBarType,
  Dialog,
  DialogType,
  DialogFooter,
  DefaultButton,
  Checkbox,
  TextField,
} from "@fluentui/react";
import AWS from "aws-sdk";
import { Auth } from "@aws-amplify/auth";

type Props = {|
  +onReviewCancel: () => void,
  +onReviewConfirm: () => void,
  +pipelineName: string,
  +actionName: string,
  +stageName: string,
  +pipelineState: $FlowFixMe,
|};
type State = {|
  +userEmail: string,
  +check1Selected: boolean,
  +check2Selected: boolean,
  +additionalNotes: string,
  +success: string | false,
  +error: string | false,
  +warning: string | false,
  +approving: boolean,
|};

export default class ReviewChecklistPrompt extends PureComponent<Props, State> {
  state: State = {
    userEmail: "",
    check1Selected: false,
    check2Selected: false,
    additionalNotes: "",
    success: false,
    error: false,
    warning: false,
    approving: false,
  };

  async componentDidMount() {
    await this.fetchData();
  }

  fetchData = async () => {
    const userEmail = (await Auth.currentUserInfo()).attributes.email;
    this.setState((state) => ({ ...state, userEmail }));
  };

  _handleInputChangeFactory = (statePropertyName: string) => (
    e: SyntheticEvent<HTMLInputElement>,
    value: boolean
  ) => {
    this.setState(() => ({ [statePropertyName]: value }));
  };

  _confirmReview = async () => {
    const {
      pipelineName,
      actionName,
      stageName,
      pipelineState,
      onReviewConfirm,
    } = this.props;
    const { userEmail, additionalNotes } = this.state;

    const creds = Auth.essentialCredentials(await Auth.currentCredentials());
    const cp = new AWS.CodePipeline({ ...creds });

    this.setState((state) => ({ ...state, approving: true }));

    const token = pipelineState.stageStates
      .find((i) => i.stageName === stageName)
      ?.actionStates.find((i) => i.actionName === actionName)?.latestExecution
      ?.token;

    if (!token) {
      console.error(
        "Token is missing for this microservice, wait for refresh or manually reload the page"
      );
      this.setState((state) => ({
        ...state,
        error: `Your approval could not be submitted at this time. We have legacy information in the state. Reload the page or wait a few minutes before retrying.`,
        approving: false,
      }));
      return;
    }

    const summary = [
      `This release has been approved by <${userEmail}> with the following notes:`,
      `${additionalNotes || "-"}`,
    ].join("; ");

    try {
      await cp
        .putApprovalResult({
          pipelineName,
          actionName,
          stageName,
          result: { status: "Approved", summary },
          token,
        })
        .promise();

      this.setState((state) => ({
        ...state,
        success: `Your approval has been successfully submitted. The pipeline will resume soon. Thank you!`,
      }));

      setTimeout(onReviewConfirm, 2500);
    } catch (e) {
      console.error("PutApprovalResult failed", e);
      this.setState((state) => ({
        ...state,
        error: `Your approval could not be submitted at this time: ${e.message}.`,
        approving: false,
      }));
    }
  };

  _confirmReject = async () => {
    const {
      pipelineName,
      actionName,
      stageName,
      pipelineState,
      onReviewConfirm,
    } = this.props;
    const { userEmail, additionalNotes } = this.state;

    const creds = Auth.essentialCredentials(await Auth.currentCredentials());
    const cp = new AWS.CodePipeline({ ...creds });

    this.setState((state) => ({ ...state, approving: true }));

    const token = pipelineState.stageStates
      .find((i) => i.stageName === stageName)
      ?.actionStates.find((i) => i.actionName === actionName)?.latestExecution
      ?.token;

    if (!token) {
      console.error(
        "Token is missing for this microservice, wait for refresh or manually reload the page"
      );
      this.setState((state) => ({
        ...state,
        error: `Your rejection could not be submitted at this time. We have legacy information in the state. Reload the page or wait a few minutes before retrying.`,
        approving: false,
      }));
      return;
    }

    const summary = [
      `This release has been rejected by <${userEmail}> with the following notes:`,
      `${additionalNotes || "-"}`,
    ].join("; ");

    try {
      await cp
        .putApprovalResult({
          pipelineName,
          actionName,
          stageName,
          result: { status: "Rejected", summary },
          token,
        })
        .promise();

      this.setState((state) => ({
        ...state,
        warning: `Your rejection has been submitted. The pipeline will terminate.`,
      }));

      setTimeout(onReviewConfirm, 2500);
    } catch (e) {
      console.error("PutApprovalResult failed", e);
      this.setState((state) => ({
        ...state,
        error: `Your approval could not be submitted at this time: ${e.message}.`,
        approving: false,
      }));
    }
  };

  render() {
    const { onReviewCancel } = this.props;
    const {
      check1Selected,
      check2Selected,
      additionalNotes,
      success,
      error,
      warning,
      approving,
    } = this.state;

    const canConfirmReview =
      check1Selected && check2Selected && additionalNotes.length > 10;

    const canRejectReview = additionalNotes.length > 10 && !canConfirmReview;

    return (
      <Stack>
        <Dialog
          hidden={false}
          onDismiss={onReviewCancel}
          type={DialogType.largeHeader}
          title="You are approving a Release"
          subText="We need a quick confirmation that you want to approve this Release and push it to the next step. Once a Release is approved at every step, it will be automatically deployed to production, without further confirmation. Please answer a few questions below from our Release Checklist:"
          modalProps={{
            isBlocking: true,
            styles: { main: { maxWidth: 600 } },
          }}
        >
          <Stack tokens={{ childrenGap: 15 }}>
            <Checkbox
              label="Does your implementation respects the Acceptance Critiera stated in the User Story?"
              checked={check1Selected}
              onChange={this._handleInputChangeFactory("check1Selected")}
            />
            <Checkbox
              label="Do you have tested manually that your implementation is correct and works as expected?"
              checked={check2Selected}
              onChange={this._handleInputChangeFactory("check2Selected")}
            />
            <TextField
              label="Release notes"
              placeholder="Write any note useful for the other reiviewers. For example, you may list any possible dependencies with other microservices or external services, or specific steps that need to be performed while releasing to production."
              value={additionalNotes}
              onChange={this._handleInputChangeFactory("additionalNotes")}
              rows={7}
              multiline
              autoAdjustHeight
            />
            <Stack>
              {success && (
                <MessageBar messageBarType={MessageBarType.success} isMultiline>
                  {success}
                </MessageBar>
              )}

              {error && (
                <MessageBar messageBarType={MessageBarType.error} isMultiline>
                  {error}
                </MessageBar>
              )}

              {warning && (
                <MessageBar messageBarType={MessageBarType.warning} isMultiline>
                  {warning}
                </MessageBar>
              )}
            </Stack>
          </Stack>
          <DialogFooter>
            <DefaultButton
              iconProps={{ iconName: "BoxMultiplySolid" }}
              disabled={!canRejectReview || approving}
              onClick={this._confirmReject}
              text="Reject"
            />
            <DefaultButton
              iconProps={{ iconName: "RecycleBin" }}
              onClick={() => {
                this.setState(
                  () => ({
                    additionalNotes:
                      "pipeline execution skipped by user request",
                  }),
                  () => this._confirmReject()
                );
              }}
              text="Skip"
            />
            <PrimaryButton
              iconProps={{ iconName: "BoxCheckmarkSolid" }}
              disabled={!canConfirmReview || approving}
              onClick={this._confirmReview}
              text="Approve"
            />
          </DialogFooter>
        </Dialog>
      </Stack>
    );
  }
}
