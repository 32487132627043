import React, { useEffect } from "react";
import { DefaultButton, PrimaryButton, Text } from "@fluentui/react";
import { Auth } from "@aws-amplify/auth";
import { Authenticator } from "aws-amplify-react";
import styled, { createGlobalStyle } from "styled-components";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import {
  Provider as AppContextProvider,
  useAppContext,
} from "./context/AppContext";

import Root from "./components/Root";

const SignInPage = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledApp = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  height: 100vh;
  box-sizing: border-box;
`;

const Header = styled.header`
  display: flex;
  flex-direction: row;
`;

const Title = styled(Text)`
  display: flex;
  flex-grow: 1;
`;

const GlobalStyles = createGlobalStyle`
  html, body, #root, #root > * {
    margin: 0;
    height: 100%;
    width: 100%;
  }
`;

const App = ({ authState }) => {
  const { setUser } = useAppContext();

  useEffect(() => {
    Auth.currentAuthenticatedUser().then((user) => {
      if (!user) {
        return;
      }
      setUser({
        groups:
          user.signInUserSession.accessToken.payload["cognito:groups"] || [],
      });
    });
  }, [setUser, authState]);

  if (authState !== "signedIn") {
    return false;
  }

  return (
    <StyledApp>
      <GlobalStyles />
      <Header>
        <Title variant="xLarge">Team Dashboard</Title>
        <DefaultButton text="Logout" onClick={() => Auth.signOut()} />
      </Header>
      <Router>
        <Switch>
          <Route
            exact
            path="/:tab(prod|pipelines|develop|user-states|company-templates|pull-contents|builds|analytics|features|activation)"
            component={Root}
          />
          <Route path="/" component={Root} />
        </Switch>
      </Router>
    </StyledApp>
  );
};

function SignIn({ authState }) {
  if (authState !== "signIn") {
    return null;
  }

  return (
    <SignInPage>
      <PrimaryButton
        text="SignIn with Okta"
        onClick={() => Auth.federatedSignIn({ customProvider: "OKTA" })}
      />
    </SignInPage>
  );
}

export default () => (
  <AppContextProvider>
    <Authenticator hideDefault>
      <App />
      <SignIn />
    </Authenticator>
  </AppContextProvider>
);
