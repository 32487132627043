// @flow

import React, { PureComponent } from "react";
import {
  Text,
  Stack,
  IconButton,
  Spinner,
  SpinnerType,
  MessageBar,
  MessageBarType,
  Shimmer,
  Panel,
  PanelType,
} from "@fluentui/react";
import AWS from "aws-sdk";
import { Auth } from "@aws-amplify/auth";

import PipelineExecutionDetails from "./PipelineExecutionDetails";
import { AppContext } from "../context/AppContext";

type Props = {|
  +onDismiss: () => void,
  +pipelineName: string,
  +pipelineState: $FlowFixMe,
|};

type State = {|
  +error: ?string,
  +success: ?string,
  +loading: boolean,
  +forceLoading: Boolean,
  +currentExecutionInfo: $FlowFixMe,
  +actionsExecutions: Array<$FlowFixMe>,
  +executionsInfo: Array<$FlowFixMe>,
  +executionIndex: number,
|};

export default class PipelineStatus extends PureComponent<Props, State> {
  _refreshInterval: IntervalID;
  state = {
    currentExecutionInfo: {},
    executionsInfo: [],
    actionsExecutions: [],
    loading: true,
    forceLoading: false,
    success: null,
    error: null,
    executionIndex: 0,
  };

  async componentDidMount() {
    await this.fetchDetails();
    this._refreshInterval = setInterval(() => this.fetchDetails(), 5 * 1000);
  }

  componentWillUnmount() {
    clearInterval(this._refreshInterval);
  }

  async componentDidUpdate(prevProps: Props) {
    if (
      prevProps.pipelineName !== this.props.pipelineName ||
      prevProps.executionIndex !== this.props.executionIndex
    ) {
      this.setState((state) => ({
        ...state,
        currentExecutionInfo: {},
        executionsInfo: [],
        actionsExecutions: [],
        success: null,
        error: null,
      }));

      if (prevProps.pipelineName !== this.props.pipelineName) {
        this.setState((state) => ({
          ...state,
          executionIndex: 0,
        }));
      }

      await this.fetchDetails();
    }
  }

  async fetchDetails() {
    this.setState((state) => ({
      ...state,
      error: null,
      loading: true,
    }));

    let { executionIndex } = this.state;
    const { pipelineName } = this.props;

    const creds = Auth.essentialCredentials(await Auth.currentCredentials());
    const cp = new AWS.CodePipeline({ ...creds });

    try {
      const executions = await cp
        .listPipelineExecutions({ pipelineName })
        .promise();

      const executionsInfo = executions.pipelineExecutionSummaries;

      // finds the oldest execution which has an InProgress status. If none, uses the most recent
      // execution
      const inProgressExecutions = executionsInfo.sort(
        (e, o) => e.startTime.valueOf() - o.startTime.valueOf()
      );

      let oldestInProgress = inProgressExecutions.findIndex(
        // finds the oldest in-progress pipeline
        (e) => e.status === "InProgress"
      );

      if (oldestInProgress === -1) {
        oldestInProgress = 0;
      }

      let oldestExecution =
        inProgressExecutions[executionIndex + oldestInProgress];

      if (oldestExecution == null) {
        oldestExecution = inProgressExecutions[0];
      }

      const currentExecutionId = oldestExecution.pipelineExecutionId;
      const executionInfoResult = await cp
        .getPipelineExecution({
          pipelineName,
          pipelineExecutionId: currentExecutionId,
        })
        .promise();

      const actionsExecutions = await cp
        .listActionExecutions({
          pipelineName,
          filter: { pipelineExecutionId: currentExecutionId },
        })
        .promise();

      this.setState((state) => ({
        ...state,
        executionsInfo,
        currentExecutionInfo: executionInfoResult.pipelineExecution,
        actionsExecutions: actionsExecutions.actionExecutionDetails.sort(
          (i, ii) => new Date(i.startTime) > new Date(ii.startTime)
        ),
        loading: false,
        forceLoading: false,
      }));
    } catch (e) {
      console.error("Cannot fetch pipeline executions list", e);
      this.setState(() => ({
        error: `Cannot render pipeline executions list: "${e.message}"`,
        loading: false,
        forceLoading: false,
      }));
    }
  }

  renderPanelContent() {
    const { pipelineName, pipelineState } = this.props;
    const {
      executionsInfo,
      currentExecutionInfo,
      actionsExecutions,
      loading,
      forceLoading,
      executionIndex,
    } = this.state;

    if (forceLoading || (loading && actionsExecutions.length === 0)) {
      return (
        <Stack tokens={{ childrenGap: 15 }}>
          <Shimmer />
          <Shimmer />
          <Shimmer />
        </Stack>
      );
    }

    if (!executionsInfo || executionsInfo.length === 0) {
      return (
        <Stack>
          <MessageBar messageBarType={MessageBarType.info} isMultiline>
            There is currently no execution for this pipeline. Check in a while.
          </MessageBar>
        </Stack>
      );
    }

    return (
      <Stack>
        {currentExecutionInfo.status === "Failed" && (
          <MessageBar messageBarType={MessageBarType.error} isMultiline>
            This execution has failed.
          </MessageBar>
        )}
        {currentExecutionInfo.status === "InProgress" && (
          <MessageBar messageBarType={MessageBarType.info} isMultiline>
            This pipeline execution is in progress. The content in this details
            pane shows the most advanced deployment, which refers to the oldest
            merged story. A pipeline can have other executions pending: those
            will be shown after this execution has completed.
          </MessageBar>
        )}

        <PipelineExecutionDetails
          key={`${pipelineName}-${executionIndex}`}
          currentExecutionInfo={currentExecutionInfo}
          actionsExecutions={actionsExecutions}
          pipelineName={pipelineName}
          pipelineState={pipelineState}
          executionIndex={executionIndex}
          onMoveToPreviousExecution={this.onMoveToPreviousExecution}
          onMoveToNextExecution={this.onMoveToNextExecution}
        />
      </Stack>
    );
  }

  _startDeploy = async () => {
    const { pipelineName } = this.props;

    const creds = Auth.essentialCredentials(await Auth.currentCredentials());
    const cp = new AWS.CodePipeline({ ...creds });

    try {
      await cp.startPipelineExecution({ name: pipelineName }).promise();
    } catch (e) {
      console.error("Cannot start pipeline execution:", e);
      this.setState((state) => ({
        ...state,
        error: `An error occurred while trying to start the deployment: ${e.message}`,
      }));
      return;
    }

    this.setState((state) => ({
      ...state,
      success:
        "The most recent version of this service is being released. If you already have a pipeline execution in progress, you will not see the one that just started. You should complete the current execution first.",
    }));
  };

  _onRenderNavigation = () => {
    const { pipelineName } = this.props;
    const { loading } = this.state;

    const buttonsStyle = { height: 30, width: 30 };

    return (
      <Stack
        horizontal
        horizontalAlign="end"
        tokens={{ childrenGap: 10, padding: 20 }}
      >
        <Stack grow={1}>
          <Text variant="large">{pipelineName}</Text>
        </Stack>
        <Stack style={buttonsStyle}>
          <IconButton
            iconProps={{ iconName: "Running" }}
            onClick={() =>
              window.open(
                `https://${AWS.config.region}.console.aws.amazon.com/codesuite/codepipeline/pipelines/${pipelineName}/view?region=${AWS.config.region}`
              )
            }
            title="View pipeline in AWS"
          />
        </Stack>
        {this.context.user?.groups?.includes("Releasers") && (
          <Stack style={buttonsStyle}>
            <IconButton
              iconProps={{ iconName: "PlaybackRate1x" }}
              onClick={this._startDeploy}
              title="Re-deploy from latest HEAD"
            />
          </Stack>
        )}
        <Stack style={buttonsStyle}>
          {loading ? (
            <Spinner type={SpinnerType.normal} />
          ) : (
            <IconButton
              iconProps={{ iconName: "Refresh" }}
              onClick={() => this.fetchDetails()}
            />
          )}
        </Stack>
      </Stack>
    );
  };

  _dismissMessageFactory = (id: string) => () => {
    this.setState((state) => ({ ...state, [id]: null }));
  };

  onMoveToNextExecution = () => {
    this.setState((state) => ({
      ...state,
      forceLoading: true,
      executionIndex: state.executionIndex - 1,
    }));
  };

  onMoveToPreviousExecution = () => {
    this.setState((state) => ({
      ...state,
      forceLoading: true,
      executionIndex: state.executionIndex + 1,
    }));
  };

  render() {
    const { onDismiss } = this.props;
    const { success, error } = this.state;

    return (
      <Panel
        type={PanelType.medium}
        isBlocking={false}
        isOpen
        onDismiss={onDismiss}
        onRenderNavigation={this._onRenderNavigation}
      >
        {success && (
          <MessageBar
            messageBarType={MessageBarType.success}
            isMultiline
            onDismiss={this._dismissMessageFactory("success")}
          >
            {success}
          </MessageBar>
        )}
        {error && (
          <MessageBar
            messageBarType={MessageBarType.error}
            isMultiline
            onDismiss={this._dismissMessageFactory("error")}
          >
            {error}
          </MessageBar>
        )}
        {this.renderPanelContent()}
      </Panel>
    );
  }
}

PipelineStatus.contextType = AppContext;
