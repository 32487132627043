// @flow

import React, { useMemo, useCallback, useState } from "react";
import {
  Dropdown,
  MessageBar,
  MessageBarType,
  Stack,
  PrimaryButton,
  Spinner,
  SpinnerSize,
} from "@fluentui/react";

import { useAppContext } from "../../context/AppContext";
import { Auth } from "@aws-amplify/auth";
import AWS from "aws-sdk";

const environmentDropdownStyles = { dropdown: { width: 300 } };

let request;

const ACCOUNT_CONTENT_DEV_ID = "573434510680";
const ACCOUNT_CONTENT_PROD_ID = "980287787224";

const pullFeatureFlags = async ({ account, accountId }) => {
  if (request) {
    request.abort();
  }
  let creds = Auth.essentialCredentials(await Auth.currentCredentials());

  const sts = new AWS.STS(creds);

  request = sts.assumeRole({
    RoleArn: `arn:aws:iam::${accountId}:role/FeatureFlagsParametersRole`,
    RoleSessionName: "FeatureFlagsParametersRole",
  });

  const assumeRoleData = await request.promise();

  const featureFlagsParametersRoleCreds = sts.credentialsFrom(
    assumeRoleData,
    creds
  );

  const ssm = new AWS.SSM(featureFlagsParametersRoleCreds);

  request = ssm.getParameter({
    Name: "/account/features_list",
  });

  let response = await request.promise();

  creds = Auth.essentialCredentials(await Auth.currentCredentials());
  const lambda = new AWS.Lambda({
    ...creds,
    httpOptions: { timeout: 300000 },
  });

  let currentValue = response.Parameter.Value;

  try {
    currentValue = JSON.parse(response.Parameter.Value);
  } catch (e) {}

  request = lambda.invoke({
    FunctionName: `arn:aws:lambda:${AWS.config.region}:${
      account.includes("prod")
        ? ACCOUNT_CONTENT_PROD_ID
        : ACCOUNT_CONTENT_DEV_ID
    }:function:hi-pull-feature-flags-spreadsheet`,
    Payload: JSON.stringify({
      currentValue,
    }),
  });

  response = await request.promise();
  const payload = JSON.parse(response.Payload);

  if (payload.errorMessage) {
    throw new Error(payload.errorMessage);
  }

  return payload;
};

const putFeatureFlags = async ({ accountId, value }) => {
  if (request) {
    request.abort();
  }

  let creds = Auth.essentialCredentials(await Auth.currentCredentials());
  const sts = new AWS.STS(creds);

  request = sts.assumeRole({
    RoleArn: `arn:aws:iam::${accountId}:role/FeatureFlagsParametersRole`,
    RoleSessionName: "FeatureFlagsParametersRole",
  });

  const assumeRoleData = await request.promise();

  const featureFlagsParametersRoleCreds = sts.credentialsFrom(
    assumeRoleData,
    creds
  );

  const ssm = new AWS.SSM(featureFlagsParametersRoleCreds);

  request = ssm.putParameter({
    Name: "/account/features_list",
    Value: JSON.stringify(value),
    Overwrite: true,
  });

  await request.promise();
};

type Props = {
  +accounts: {| [environment: string]: string |},
};

const Features = ({ accounts }: Props) => {
  const { userStateEnv, setUserStateEnv } = useAppContext();
  const [diff, setDiff] = useState();
  const [value, setValue] = useState();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [error, setError] = useState<?string>(null);

  const fetch = useCallback(() => {
    if (!userStateEnv) {
      return;
    }

    setIsLoading(true);
    setError(null);
    setDiff(null);
    setValue(null);
    pullFeatureFlags({
      account: userStateEnv,
      accountId: accounts[userStateEnv],
    })
      .then(({ diff, value }) => {
        setDiff(diff);
        setValue(value);
      })
      .catch((e) => {
        setError(e.message);
      })
      .finally(() => setIsLoading(false));
  }, [accounts, userStateEnv]);

  const commitChanges = useCallback(() => {
    setIsSaving(true);
    setError(null);
    putFeatureFlags({ value, accountId: accounts[userStateEnv] })
      .then(() => {
        alert("Save completed!\nPull again to verify.");
      })
      .catch((e) => {
        setError(e.message);
      })
      .finally(() => setIsSaving(false));
  }, [accounts, value, userStateEnv]);

  const formattedDiff = useMemo(() => {
    if (!diff) {
      return <li>No changes</li>;
    }

    const lines = diff.split("\n");

    const list = lines.map((line) => {
      if (line.startsWith("+")) {
        return (
          <li style={{ color: "green", whiteSpace: "pre" }}>
            {line}
            <br />
          </li>
        );
      }
      if (line.startsWith("-")) {
        return (
          <li style={{ color: "red", whiteSpace: "pre" }}>
            {line}
            <br />
          </li>
        );
      }
      return (
        <li style={{ color: "gray", whiteSpace: "pre" }}>
          {line}
          <br />
        </li>
      );
    });

    list.push(
      <li>
        <Stack horizontal tokens={{ childrenGap: 12 }}>
          <PrimaryButton
            style={{ backgroundColor: "#cb0000" }}
            iconProps={{ iconName: "DatabaseSync" }}
            onClick={commitChanges}
            text="Approve and release"
            disabled={isSaving}
          />
          {isSaving && <Spinner size={SpinnerSize.large} />}
        </Stack>
      </li>
    );

    return list;
  }, [isSaving, diff, commitChanges]);

  const handleAccountChange = useCallback(
    (e, { key }) => {
      setUserStateEnv(key);
    },
    [setUserStateEnv]
  );

  const environmentOptions = useMemo<[{ key: string, text: string }]>(
    () =>
      Object.keys(accounts).map((key) => ({
        key,
        text: key,
      })),
    [accounts]
  );

  return (
    <Stack tokens={{ childrenGap: 15 }}>
      <Stack
        horizontal
        tokens={{ childrenGap: 12 }}
        style={{ alignItems: "flex-end" }}
      >
        <Dropdown
          required
          label="Environment"
          placeholder="Select the Environment"
          options={environmentOptions}
          onChange={handleAccountChange}
          selectedKey={userStateEnv}
          styles={environmentDropdownStyles}
        />
        <PrimaryButton
          iconProps={{ iconName: "DatabaseSync" }}
          text="Preview changes"
          disabled={!userStateEnv}
          onClick={fetch}
        />
        {error && (
          <MessageBar messageBarType={MessageBarType.error} isMultiline>
            {error}
          </MessageBar>
        )}
      </Stack>
      <Stack>
        {isLoading && <Spinner size={SpinnerSize.large} />}

        {!isLoading && typeof diff === "string" && (
          <MessageBar
            messageBarType={MessageBarType.info}
            isMultiline
            style={{ marginBottom: 5 }}
          >
            <ul style={{ fontFamily: "monospace", listStyleType: "none" }}>
              {formattedDiff}
            </ul>
          </MessageBar>
        )}
      </Stack>
    </Stack>
  );
};

export default Features;
