// @flow

import React, { PureComponent } from "react";
import {
  Text,
  Panel,
  ChoiceGroup,
  TextField,
  Stack,
  PrimaryButton,
  Dropdown,
  ProgressIndicator,
  MessageBar,
  MessageBarType,
} from "@fluentui/react";
import styled from "styled-components";
import AWS from "aws-sdk";
import { Auth } from "@aws-amplify/auth";
import type { StyledComponent } from "styled-components";

type Props = {|
  +isOpen: boolean,
  +onClose: () => void,
  +microserviceName: string,
  +targetAccount?: string,
  +developerName: string,
  +branchName: ?string,
  +accounts: {| [accountId: string]: string |},
|};

type State = {|
  +sourceVersionKey: ?string,
  +sourceVersionOptions: Array<{| key: string, text: string |}>,
  +choosenAccount: ?string,
  +deploying: boolean,
  +error: string | false,
|};

const Container: StyledComponent<{||}, {||}, HTMLDivElement> = styled.div`
  display: flex;
  flex-direction: column;
`;

export default class ExecuteDeployPanel extends PureComponent<Props, State> {
  state: State = {
    sourceVersionKey: null,
    sourceVersionOptions: [],
    choosenAccount: null,
    deploying: false,
    error: false,
  };
  static getDerivedStateFromProps(props: Props, state: State) {
    const { branchName, targetAccount } = props;

    const nonMasterBranchName =
      branchName === "master" ? undefined : branchName;
    const sourceVersionOptions = [
      { key: "master", text: `master's HEAD` },
      ...(nonMasterBranchName
        ? [{ key: branchName, text: `Story's HEAD` }]
        : []),
    ];

    return {
      ...state,
      sourceVersionOptions,
      choosenAccount: state.choosenAccount || targetAccount,
      sourceVersionKey: state.sourceVersionKey || branchName || "master",
    };
  }

  handleSourceVersionChange = (
    event: SyntheticEvent<HTMLInputElement>,
    option: {| key: string, text: string |}
  ) => {
    this.setState((state) => ({
      sourceVersionKey: option.key,
    }));
  };

  getAccountOptions = (): Array<{| key: string, text: string |}> => {
    const { accounts } = this.props;

    return Object.entries(accounts).map(([developerName, accountId]) => ({
      key: String(accountId),
      text: developerName,
    }));
  };

  startDeploy = async () => {
    const { sourceVersionKey, choosenAccount } = this.state;
    const { onClose, microserviceName, developerName } = this.props;

    this.setState((state) => ({ ...state, deploying: true, error: false }));

    const projectName = developerName
      ? `devbuild-${microserviceName}-${developerName}`
      : `pipeline-deployer-${microserviceName}`;

    try {
      const cb = new AWS.CodeBuild({
        ...Auth.essentialCredentials(await Auth.currentCredentials()),
      });
      await cb
        .startBuild({
          projectName,
          sourceVersion: sourceVersionKey,
          environmentVariablesOverride: [
            {
              name: "TARGET_ACCOUNT_ID",
              type: "PLAINTEXT",
              value: choosenAccount,
            },
          ],
          // extra params only for codebuild overrides
          ...(developerName
            ? {}
            : {
                artifactsOverride: { type: "NO_ARTIFACTS" },
                sourceTypeOverride: "GITHUB",
                sourceVersion: "master",
                sourceLocationOverride: `https://github.com/dtttd/${microserviceName}.git`,
              }),
        })
        .promise();

      this.setState((state) => ({ ...state, deploying: false }));
      onClose();
    } catch (e) {
      this.setState((state) => ({
        ...state,
        deploying: false,
        error: `Deploy cannot be started: ${e.message}`,
      }));
      console.error("Deploy error", e);
      return;
    }
  };

  onAccountChoose = (e: Event, item: {| key: string, text: string |}) => {
    this.setState((state) => ({
      ...state,
      choosenAccount: item.key,
    }));
  };

  render() {
    const {
      isOpen,
      onClose,
      microserviceName,
      developerName,
      branchName,
    } = this.props;

    const {
      sourceVersionKey,
      sourceVersionOptions,
      choosenAccount,
      deploying,
      error,
    } = this.state;

    const canDeploy = Boolean(
      microserviceName && sourceVersionKey && choosenAccount && !deploying
    );

    return (
      <Container>
        <Panel
          headerText="Deploy"
          isOpen={isOpen}
          onDismiss={onClose}
          isLightDismiss
          closeButtonAriaLabel="Close"
        >
          <Stack tokens={{ childrenGap: 20 }}>
            <Text>
              From this menu you can deploy the selected microservice to any
              account. Please review the options below and confirm.
            </Text>
            <TextField
              disabled
              required
              label="Microservice Name"
              defaultValue={microserviceName}
            />
            <TextField
              disabled
              label="Source Developer"
              defaultValue={developerName}
            />
            <TextField
              disabled
              label="Source Branch"
              defaultValue={branchName}
            />
            <ChoiceGroup
              required
              selectedKey={sourceVersionKey}
              onChange={this.handleSourceVersionChange}
              options={sourceVersionOptions}
              label="Which version do you want to deploy?"
            />

            <Stack>
              <Dropdown
                required
                label="Target Account"
                placeholder="Select the Account ID to deploy to"
                onChange={this.onAccountChoose}
                options={this.getAccountOptions()}
                selectedKey={choosenAccount}
              />
              {choosenAccount && (
                <Text variant="small">
                  This service will be deployed to <code>{choosenAccount}</code>
                </Text>
              )}
            </Stack>

            <PrimaryButton
              text="Deploy"
              onClick={this.startDeploy}
              disabled={!canDeploy}
            />

            {error && (
              <Stack tokens={{ childrenGap: 15 }}>
                <MessageBar messageBarType={MessageBarType.error} isMultiline>
                  {error}
                  <br />
                  If the project does not exist, probably it is still being
                  created. If the error persists for more than 5 minutes, try to
                  close and re-open the PR associated to it.
                </MessageBar>
              </Stack>
            )}

            {deploying && (
              <ProgressIndicator description="Requesting microservice deploy..." />
            )}
          </Stack>
        </Panel>
      </Container>
    );
  }
}
