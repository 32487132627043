// @flow

import React, { PureComponent } from "react";
import {
  Link,
  Text,
  Stack,
  Icon,
  PrimaryButton,
  // DefaultButton,
  IconButton,
  Spinner,
  SpinnerSize,
  MessageBar,
  MessageBarType,
  ShimmerLine,
} from "@fluentui/react";
import { Card } from "@uifabric/react-cards";
import moment from "moment";
// import AWS from "aws-sdk";
// import { Auth } from "@aws-amplify/auth";

import ReviewChecklistPrompt from "./ReviewChecklistPrompt";
import { AppContext } from "../context/AppContext";

type ActionExecution = {|
  +stageName: string,
  +actionName: string,
  +startTime: string,
  +status: "Failed" | "Succeded" | "InProgress",
  +input: {|
    resolvedConfiguration?: $FlowFixMe,
    actionTypeId: {|
      category: "Source" | "Build" | "Approval",
      provider?: "Manual",
    |},
  |},
  +pipelineExecutionId: string,
  +actionExecutionId: string,
  +output?: {|
    outputArtifacts: Array<$FlowFixMe>,
    executionResult?: {|
      externalExecutionSummary?: string,
      externalExecutionUrl?: string,
    |},
  |},
|};

type Props = {|
  +actionsExecutions: Array<ActionExecution>,
  +pipelineName: string,
  +pipelineState: $FlowFixMe,
  +currentExecutionInfo: $FlowFixMe,
  onMoveToPreviousExecution: () => {},
  executionIndex: number,
  onMoveToNextExecution: () => {},
|};

type State = {|
  +error: ?string,
  +success: ?string,
  +approving: boolean,
|};

// const getS3Link = async ({ actionExecution }) => {
//   const s3 = new AWS.S3({
//     ...Auth.essentialCredentials(await Auth.currentCredentials()),
//     signatureVersion: "v4",
//     region: "eu-west-1",
//   });
//
//   const location = actionExecution.output?.outputArtifacts[0]?.s3location;
//   if (!location) {
//     return null;
//   }
//
//   const url = await s3.getSignedUrlPromise("getObject", {
//     Bucket: location.bucket,
//     Key: location.key,
//     ResponseContentDisposition: "attachment;filename=artifact.zip",
//   });
//
//   return url;
// };

/* prettier-ignore */
export default class PipelineExecutionDetails extends PureComponent<Props, State> {
  state: State = {
    error: null,
    success: null,
    approving: false,
  };

  _renderItemColumn = (item: $FlowFixMe, index: number, column: $FlowFixMe) => {
    const fieldContent = item[column.fieldName];

    switch (column.key) {
      case "startTime":
        return <Text>{fieldContent.toISOString()}</Text>;

      default:
        return <span>{fieldContent}</span>;
    }
  };

  _getColumns = () => {
    return [
      {
        key: "startTime",
        fieldName: "startTime",
        name: "Start Time",
        minWidth: 200,
        maxWidth: 300,
      },
    ];
  };

  _getActionIcon = ({
    actionExecution,
  }: {|
    +actionExecution: ActionExecution,
  |}) => {
    const category = actionExecution.input.actionTypeId.category;
    if (category === "Source") {
      return <Icon iconName="OpenSource" />;
    }
    if (category === "Build") {
      return <Icon iconName="BuildDefinition" />;
    }
    if (category === "Approval") {
      return <Icon iconName="CheckList" />;
    }
  };

  _getActionStatusIcon = ({
    actionExecution,
  }: {|
    +actionExecution: ActionExecution,
  |}) => {
    const status = actionExecution.status;
    if (status === "Failed") {
      return <Icon iconName="BoxMultiplySolid" style={{ color: "red" }} />;
    }
    if (status === "Succeeded") {
      return <Icon iconName="BoxCheckmarkSolid" style={{ color: "green" }} />;
    }
    if (status === "InProgress") {
      return <Spinner size={SpinnerSize.medium} />;
    }
  };

  _dismissMessageFactory = (id: string) => () => {
    this.setState((state) => ({ ...state, [id]: null }));
  };

  _showReviewChecklist = () => {
    this.setState((state) => ({ ...state, approving: true }));
  };

  _hideReviewChecklist = () => {
    this.setState((state) => ({ ...state, approving: false }));
  };

  render() {
    const { actionsExecutions, pipelineName, pipelineState, onMoveToPreviousExecution, onMoveToNextExecution } = this.props;
    const { approving, error, success } = this.state;

    let lastStageName = "";

    if (!actionsExecutions || actionsExecutions.length === 0) {
      return <ShimmerLine />;
    }

    return (
      <Stack tokens={{ childrenGap: 20 }} style={{ marginTop: 20 }}>
        {actionsExecutions.map((actionExecution, index) => (
          <Stack
            key={`${actionExecution.actionExecutionId}`}
            verticalAlign="center"
            tokens={{ childrenGap: 20 }}
          >
            {
              actionExecution.stageName === "Source" &&
              actionExecution.output
                ?.executionResult
                ?.externalExecutionSummary &&

              <Stack horizontal>
                <Text variant="xLarge">
                  Release:{" "}
                  <Link href={`https://app.clubhouse.io/dtttd/story/${getChIdFromActionExecution(actionExecution)}`} style={{ color: '#cb0000' }}>{getChIdFromActionExecution(actionExecution)}</Link>
                </Text>

                <Stack>
                  <IconButton
                    iconProps={{ iconName: "DoubleChevronLeft8" }}
                    onClick={onMoveToPreviousExecution}
                    title="Newer execution"
                  />
                </Stack>

                <Stack>
                  <IconButton
                    iconProps={{ iconName: "DoubleChevronRight8" }}
                    onClick={onMoveToNextExecution}
                    title="Older execution"
                  />
                </Stack>
              </Stack>
            }

            {actionExecution.stageName !== lastStageName &&
              ((lastStageName = actionExecution.stageName),
              (
                <Stack tokens={{ childrenGap: 20 }}>
                  {index !== 0 && <Icon iconName="DoubleDownArrow" />}
                  <Text
                    variant="medium"
                    styles={{ root: { fontWeight: "bold" } }}
                  >
                    {actionExecution.stageName}
                  </Text>
                </Stack>
              ))}

            <Stack>
              <Card horizontal tokens={{ childrenMargin: 12 }}>
                <Card.Section grow horizontalAlign="start">
                  <Text
                    variant="small"
                    title={moment(actionExecution.startTime).format("llll Z")}
                  >
                    {moment(actionExecution.startTime).fromNow()}
                  </Text>

                  <Text>
                    {this._getActionIcon({ actionExecution })}{" "}
                    {actionExecution.actionName}
                  </Text>

                  {actionExecution.output &&
                    actionExecution.output.executionResult && (
                      <React.Fragment>
                        <Text variant="small">
                          <Link
                            href={
                              actionExecution.output.executionResult
                                .externalExecutionUrl
                            }
                            target="_blank"
                          >
                            <Icon iconName="OpenInNewWindow" />{" "}
                            {actionExecution.actionExecutionId.substr(0, 8)}
                          </Link>{" "}
                          {
                          getActionResultText(actionExecution)
                          }
                        </Text>

                        {/*actionExecution.input.actionTypeId.provider ===
                          "CodeBuild" &&
                          actionExecution.output?.outputArtifacts &&
                          actionExecution.output.outputArtifacts[0]
                            ?.s3location && (
                            <DefaultButton
                              iconProps={{ iconName: "CloudDownload" }}
                              text="Download Artifact"
                              onClick={() =>
                                getS3Link({ actionExecution }).then((url) =>
                                  window.prompt(
                                    "Copy this URL to download the artifact:",
                                    url
                                  )
                                )
                              }
                            />
                            )*/}
                      </React.Fragment>
                    )}

                  {actionExecution.input.actionTypeId.provider === "Manual" &&
                    actionExecution.status === "InProgress" && (
                      <React.Fragment>
                        <Text>
                          {
                            actionExecution.input.resolvedConfiguration
                              ?.CustomData
                          }
                        </Text>

                        <Text>
                          <span style={{ fontWeight: "bold" }}>Link:</span>{" "}
                          {actionExecution.input.resolvedConfiguration
                            ?.ExternalEntityLink && (
                            <Link
                              target="_blank"
                              href={
                                actionExecution.input.resolvedConfiguration
                                  .ExternalEntityLink
                              }
                            >
                              {
                                actionExecution.input.resolvedConfiguration
                                  .ExternalEntityLink
                              }
                            </Link>
                          )}
                        </Text>

                        {
                          this.context.user?.groups?.includes("Releasers") && <PrimaryButton
                              iconProps={{ iconName: "TriggerApproval" }}
                              text={approving ? "Approving..." : "Submit Approval"}
                              disabled={approving}
                              onClick={this._showReviewChecklist}
                            />
                        }

                        {approving && (
                          <ReviewChecklistPrompt
                            pipelineName={pipelineName}
                            actionName={actionExecution.actionName}
                            stageName={actionExecution.stageName}
                            pipelineState={pipelineState}
                            onReviewConfirm={this._hideReviewChecklist}
                            onReviewCancel={this._hideReviewChecklist}
                          />
                        )}
                      </React.Fragment>
                    )}
                </Card.Section>
                <Card.Section
                  grow={0}
                  tokens={{ padding: "0px 0px 0px 12px" }}
                  verticalAlign="center"
                >
                  {this._getActionStatusIcon({ actionExecution })}
                </Card.Section>
              </Card>
            </Stack>
          </Stack>
        ))}

        {success && (
          <MessageBar
            messageBarType={MessageBarType.success}
            isMultiline
            onDismiss={this._dismissMessageFactory("success")}
          >
            {success}
          </MessageBar>
        )}

        {error && (
          <MessageBar
            messageBarType={MessageBarType.error}
            isMultiline
            onDismiss={this._dismissMessageFactory("error")}
          >
            {error}
          </MessageBar>
        )}
      </Stack>
    );
  }
}

PipelineExecutionDetails.contextType = AppContext;

function getActionResultText(actionExecution) {
  if (
    actionExecution.output.executionResult.externalExecutionSummary?.includes(
      "has been approved"
    )
  ) {
    return (
      <Text
        style={{ fontFamily: "monospace", backgroundColor: "#8f8" }}
        variant="medium"
      >
        {actionExecution.output.executionResult.externalExecutionSummary}
      </Text>
    );
  }

  if (
    actionExecution.output.executionResult.externalExecutionSummary?.includes(
      "has been reject"
    )
  ) {
    return (
      <Text
        style={{ fontFamily: "monospace", backgroundColor: "#f88" }}
        variant="medium"
      >
        {actionExecution.output.executionResult.externalExecutionSummary}
      </Text>
    );
  }

  return (
    <Text>
      {actionExecution.output.executionResult.externalExecutionSummary}
    </Text>
  );
}

function getChIdFromActionExecution(actionExecution) {
  const tokens = /#(\d+).*(ch(\d+))/.exec(
    actionExecution.output?.executionResult?.externalExecutionSummary
  );

  if (tokens == null) {
    return "(💩 not a story merge commit)";
  }

  const [, prNumber, storyId] = tokens;
  return `${storyId} / pr${prNumber}`;
}
