// @flow

import React, { useState, useCallback, useContext } from "react";
import type { State as UserState, UserJourneys } from "@dtttd/types/hi";

type Status = {|
  +success: string | false,
  +error: string | false,
  +warning: string | false,
|};

type AppContextType = {|
  +companyId: string,
  +setCompanyId: (string) => void,
  +userStateEnv: string,
  +setUserStateEnv: (string) => void,
  +deviceId: string,
  +setDeviceId: (string) => void,
  +locale: string,
  +setLocale: (string) => void,
  +activeDay: number | "",
  +setActiveDay: (number | "") => void,
  +extraNeeds: string | "",
  +setExtraNeeds: string | "",
  +userState: string,
  +setUserState: (string) => void,
  +initialUserState: ?UserState,
  +setInitialUserState: (?UserState) => void,
  +companyState: string,
  +setCompanyState: (string) => void,
  +userStateStatus: Status,
  +setUserStateStatus: (Status) => void,
  +companyStateStatus: Status,
  +setCompanyStateStatus: (Status) => void,
  +userJourneys: ?UserJourneys,
  +setUserJourneys: (UserJourneys) => void,
  +journeys: ?UserJourneys,
  +setJourneys: (UserJourneys) => void,
  +deviceIds: string[],
  +setDeviceIds: (string[]) => void,
  +user: *,
  +setUser: (*) => void,
  +featureFlags: ?({ storyId: string }[]),
  +setFeatureFlags: ({ storyId: string }[]) => void,
|};

const AppContext = React.createContext<AppContextType>();

const Provider = ({ children }: {| children: React$Element<any> |}) => {
  const [companyId, setCompanyId] = useState<string>("");
  const [userStateEnv, setUserStateEnv] = useState<string>("");
  const [deviceId, setDeviceId] = useState<string>("");
  const [activeDay, setActiveDay] = useState<number | "">("");
  const [userState, setUserState] = useState<string>("");
  const [extraNeeds, setExtraNeeds] = useState<string>([]);
  const [locale, setLocale] = useState<string>("");
  const [initialUserState, setInitialUserState] = useState<?UserState>(null);
  const [companyState, setCompanyState] = useState<string>("");
  const [userJourneys, setUserJourneys] = useState<?UserJourneys>(null);
  const [journeys, setJourneys] = useState<?UserJourneys>(null);
  const [deviceIds, setDeviceIds] = useState<string[]>([]);
  const [companyStateStatus, _setCompanyStateStatus] = useState<Status>({
    success: "",
    error: "",
    warning: "",
  });
  const [userStateStatus, _setUserStateStatus] = useState<Status>({
    success: "",
    error: "",
    warning: "",
  });
  const [user, setUser] = useState(null);
  const [featureFlags, setFeatureFlags] = useState<{ storyId: string }[]>([]);

  const setCompanyStateStatus = useCallback(
    (newValues) => {
      _setCompanyStateStatus({
        ...companyStateStatus,
        ...newValues,
      });
    },
    [_setCompanyStateStatus, companyStateStatus]
  );

  const setUserStateStatus = useCallback(
    (newValues) => {
      _setUserStateStatus({
        ...userStateStatus,
        ...newValues,
      });
    },
    [_setUserStateStatus, userStateStatus]
  );

  return (
    <AppContext.Provider
      value={{
        companyId,
        userStateEnv,
        deviceId,
        activeDay,
        locale,
        setLocale,
        extraNeeds,
        setExtraNeeds,
        setCompanyId,
        setUserStateEnv,
        setDeviceId,
        setActiveDay,
        userState,
        setUserState,
        initialUserState,
        setInitialUserState,
        companyState,
        setCompanyState,
        userStateStatus,
        setUserStateStatus,
        companyStateStatus,
        setCompanyStateStatus,
        userJourneys,
        setUserJourneys,
        journeys,
        setJourneys,
        setDeviceIds,
        deviceIds,
        user,
        setUser,
        featureFlags,
        setFeatureFlags,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

const useAppContext = () => useContext(AppContext);

export { Provider, AppContext, useAppContext };
