// @flow

import React, { PureComponent } from "react";
import {
  Text,
  Stack,
  PrimaryButton,
  MessageBar,
  MessageBarType,
  DefaultButton,
  Dropdown,
  ComboBox,
  TextField,
} from "@fluentui/react";
import { DropdownMenuItemType } from "@fluentui/react/lib/Dropdown";
import AWS from "aws-sdk";
import { v4 as uuidv4 } from 'uuid';
import { Auth } from "@aws-amplify/auth";

import HabitsDetails from "./HabitsDetails";

import formatDays from "../lib/formatDays";
import { AppContext } from "../context/AppContext";
import getS3 from "../lib/getS3";
import accounts from "../accounts";
import DeviceIdSelect from "./DeviceIdSelect";

type Props = {|
  accounts: { [environment: string]: string },
|};

const locales = [
  { key: "en-GB", text: "English" },
  { key: "it-IT", text: "Italiano" },
];

const getJourneyFromCompanyTemplateLabel = ({
  userJourneys,
  userStateJourneys,
  journey,
}) =>
  userJourneys &&
  userJourneys.some((uj) => uj === journey.journeyId) &&
  userStateJourneys.every((uj) => uj !== journey.journeyId)
    ? " (company template)"
    : "";

let request;
const getFeatureFlags = async ({ accountId }) => {
  if (request) {
    request.abort();
  }
  let creds = Auth.essentialCredentials(await Auth.currentCredentials());

  const sts = new AWS.STS(creds);

  request = sts.assumeRole({
    RoleArn: `arn:aws:iam::${accountId}:role/FeatureFlagsParametersRole`,
    RoleSessionName: "FeatureFlagsParametersRole",
  });

  const assumeRoleData = await request.promise();

  const featureFlagsParametersRoleCreds = sts.credentialsFrom(
    assumeRoleData,
    creds
  );

  const ssm = new AWS.SSM(featureFlagsParametersRoleCreds);

  request = ssm.getParameter({
    Name: "/account/features_list",
  });

  let response = await request.promise();

  return JSON.parse(response.Parameter.Value);
};

export default class UserStateEditor extends PureComponent<Props, {||}> {
  _setHistory = (newDeviceId) => {
    const { userStateEnv } = this.context;
    const currentHistory = JSON.parse(localStorage.getItem("history") || "{}");
    let stageHistory;
    if (currentHistory[userStateEnv]) {
      stageHistory = [
        ...new Set([...currentHistory[userStateEnv], newDeviceId]),
      ].slice(-10);
    } else {
      stageHistory = [newDeviceId];
    }

    localStorage.setItem(
      "history",
      JSON.stringify({ ...currentHistory, [userStateEnv]: stageHistory })
    );
  };

  fetchData = async () => {
    const { deviceId, userStateEnv } = this.context;

    let errorMessage = "";
    if (!deviceId) {
      errorMessage = `${errorMessage}Device Id is required!`;
    }

    if (!userStateEnv) {
      errorMessage = `${errorMessage}\nEnvironment is required!`;
    }

    if (errorMessage) {
      this.context.setUserStateStatus({
        error: errorMessage,
      });
      return;
    }

    const environment = accounts.find(({ key }) => key === userStateEnv);

    const s3 = await getS3();

    try {
      const userStateResult = await s3
        .getObject({
          Bucket: environment.userMetaBucketName,
          Key: `userstate/${deviceId}.json`,
        })
        .promise();

      const initialUserState = JSON.parse(
        userStateResult.Body.toString("utf-8")
      );

      const userState = JSON.stringify(initialUserState, null, 2);

      this.context.setUserStateStatus({
        error: false,
        success: false,
        warning: false,
      });
      this.context.setInitialUserState(initialUserState);

      this.context.setUserState(userState);
      this.context.setActiveDay(
        initialUserState.activeDays ? initialUserState.activeDays + 1 : 1 || ""
      );
      this.context.setLocale(initialUserState.locale);
      this.context.setExtraNeeds(initialUserState.extraNeeds || []);
      this._setHistory(deviceId);
    } catch (e) {
      this.context.setUserStateStatus({
        error: false,
        success: false,
        warning: `User State does not exist for this deviceId in this environment. Error: ${e.message}`,
      });
      this.context.setUserState("");
      this.context.setInitialUserState(null);
    }

    try {
      const creds = Auth.essentialCredentials(await Auth.currentCredentials());
      const lambda = new AWS.Lambda({ ...creds });

      const { Payload } = await lambda
        .invoke({
          FunctionName: `arn:aws:lambda:${AWS.config.region}:${
            this.props.accounts[environment.key]
          }:function:get-user-journeys`,
          Payload: JSON.stringify({
            did: deviceId,
          }),
        })
        .promise();

      const parsedPayload = JSON.parse(Payload);

      if (parsedPayload.errorMessage) {
        throw new Error(parsedPayload.errorMessage);
      }

      this.context.setJourneys(parsedPayload);
    } catch (e) {
      this.context.setUserStateStatus({
        error: `Error while fetching journeys: ${e.message}`,
      });
    }

    try {
      const creds = Auth.essentialCredentials(await Auth.currentCredentials());
      const lambda = new AWS.Lambda({
        ...creds,
        retry: false,
        region: AWS.config.region,
      });

      const functionName = `arn:aws:lambda:${AWS.config.region}:${
        this.props.accounts[environment.key]
      }:function:get-state`;

      const result = await lambda
        .invoke({
          FunctionName: functionName,
          Payload: JSON.stringify({
            did: deviceId,
            currentTime: Date.now(),
            isLeafTimestamp: false,
          }),
        })
        .promise();

      const { currentJourney } = JSON.parse(result.Payload);
      this.context.setUserJourneys(currentJourney ? [currentJourney] : []);
    } catch (e) {
      this.context.setUserStateStatus({
        error: `Error while invoking getState lambda: ${e.message}`,
      });
    }

    try {
      const featureFlags = await getFeatureFlags({
        accountId: this.props.accounts[environment.key],
      });
      this.context.setFeatureFlags(featureFlags);
    } catch (e) {
      this.context.setUserStateStatus({
        error: `Error while get feature flags: ${e.message}`,
      });
    }
  };

  _fetchState = async () => {
    this.context.setUserState("");
    this.context.setInitialUserState(null);
    await this.fetchData();
  };

  _saveUpdate = async ({ state }) => {
    const { deviceId, userStateEnv, userState } = this.context;

    this.context.setUserStateStatus({
      error: false,
      success: false,
      warning: false,
    });

    const s3 = await getS3();

    try {
      const parsedUserState = JSON.parse(state || userState);

      const environment = accounts.find(({ key }) => key === userStateEnv);

      const userStateResult = await s3
        .putObject({
          Bucket: environment.userMetaBucketName,
          Key: `userstate/${deviceId}.json`,
          Body: JSON.stringify(parsedUserState), // remove newlines
          ACL: "bucket-owner-full-control",
        })
        .promise();

      this.context.setUserStateStatus({
        success: `User State saved successfully: ${userStateResult.ETag}`,
        error: false,
        warning: false,
      });
      this.context.setInitialUserState(parsedUserState);
    } catch (e) {
      this.context.setUserStateStatus({
        error: `Error saving updated user state: ${e.message}`,
        success: false,
        warning: false,
      });
    }
  };

  _handleUserStateChange = (
    e: SyntheticEvent<HTMLInputElement>,
    value: string
  ) => {
    try {
      JSON.parse(value);
      this.context.setUserStateStatus({ warning: false });
    } catch (e) {
      this.context.setUserStateStatus({ warning: `${e.message}` });
    } finally {
      this.context.setUserState(value);
    }
  };

  _handleEnvironmentChoice = async (
    e: SyntheticEvent<HTMLInputElement>,
    {
      key,
    }: {|
      key: string,
      text: string,
      contentStorageBucketName: string,
      userMetaBucketName: string,
    |}
  ) => {
    this.context.setUserStateEnv(key);
  };

  _handleDeviceIdChange = async (value: string) => {
    this.context.setDeviceId(value);
  };

  _handleActiveDayChange = (
    e: SyntheticEvent<HTMLInputElement>,
    value: string
  ) => {
    try {
      let newActiveDay = value || 0;

      const tempUserState = JSON.parse(this.context.userState);
      const ts = Date.now();
      tempUserState.activeDays = parseInt(newActiveDay, 10) - 1;
      tempUserState.activeDaysUpdatedOn = ts;
      tempUserState.privacyCompletedOn = tempUserState.privacyCompletedOn || ts;
      tempUserState.semanticsHistory = (
        tempUserState.semanticsHistory || []
      ).map((item) => {
        const consumedOn = item.consumedOn || 0;
        const subtracted = consumedOn - 24 * 60 * 60 * 1000;
        return {
          ...item,
          consumedOn: subtracted >= 0 ? subtracted : 0,
        };
      });

      this.context.setUserState(JSON.stringify(tempUserState, null, 2));
      this.context.setActiveDay(parseInt(newActiveDay, 10));
    } catch (e) {
      this.context.setUserStateStatus({ warning: `${e.message}` });
    }
  };

  _handleExtraNeedsChange = (
    e: SyntheticEvent<HTMLInputElement>,
    value: string
  ) => {
    try {
      const tempUserState = JSON.parse(this.context.userState);
      const extraNeeds = value.split(",").map((n) => n.trim());

      tempUserState.extraNeeds = extraNeeds;

      this.context.setUserState(JSON.stringify(tempUserState, null, 2));
      this.context.setExtraNeeds(extraNeeds);
    } catch (err) {
      this.context.setUserStateStatus({ warning: `${err.message}` });
    }
  };

  _clearSemanticsHistory = () => {
    try {
      const tempUserState = JSON.parse(this.context.userState);
      tempUserState.semanticsHistory = [];
      this.context.setUserState(JSON.stringify(tempUserState, null, 2));
    } catch (e) {
      this.context.setUserStateStatus({ warning: `${e.message}` });
    }
  };

  _clearLastItemInSemanticsHistory = () => {
    try {
      const tempUserState = JSON.parse(this.context.userState);
      tempUserState.semanticsHistory.pop();
      this.context.setUserState(JSON.stringify(tempUserState, null, 2));
    } catch (e) {
      this.context.setUserStateStatus({ warning: `${e.message}` });
    }
  };

  _handleLocaleChange = async (
    e: SyntheticEvent<HTMLInputElement>,
    {
      key,
    }: {|
      key: string,
      text: string,
    |}
  ) => {
    try {
      const tempUserState = JSON.parse(this.context.userState);
      tempUserState.locale = key;
      this.context.setUserState(JSON.stringify(tempUserState, null, 2));
      this.context.setLocale(key);
    } catch (err) {
      this.context.setUserStateStatus({ warning: `${err.message}` });
    }
  };

  _handleUJChange = (e: SyntheticEvent<HTMLInputElement>, item: $FlowFixMe) => {
    try {
      const tempUserState = JSON.parse(this.context.userState);
      tempUserState.currentJourney = item.key || "";

      tempUserState.journeyChangeDate = new Date().getTime();
      this.context.setUserState(JSON.stringify(tempUserState, null, 2));
      this.context.setUserJourneys(
        tempUserState.currentJourney ? [tempUserState.currentJourney] : []
      );
    } catch (err) {
      this.context.setUserStateStatus({ warning: `${err.message}` });
    }
  };

  _handleFeaturesChange = (
    e: SyntheticEvent<HTMLInputElement>,
    item: $FlowFixMe
  ) => {
    try {
      const tempUserState = JSON.parse(this.context.userState);
      tempUserState.extraFeatures = {
        ...(tempUserState.extraFeatures || {}),
        [item.key]: item.selected,
      };
      this.context.setUserState(JSON.stringify(tempUserState, null, 2));
    } catch (err) {
      this.context.setUserStateStatus({ warning: `${err.message}` });
    }
  };

  resendFirstActivity = async () => {
    try {
      this.context.setUserStateStatus({
        error: false,
        warning: false,
        success: false,
      });

      const { initialUserState, deviceId, userStateEnv } = this.context;

      const state = JSON.stringify(initialUserState, null, 2);
      this.context.setUserState(state);

      await this._saveUpdate({ state });

      const creds = Auth.essentialCredentials(await Auth.currentCredentials());
      const lambda = new AWS.Lambda({ ...creds });

      const params = {
        FunctionName: `arn:aws:lambda:eu-west-1:${this.props.accounts[userStateEnv]}:function:realtimefeedback`,
        Payload: JSON.stringify({
          cid: initialUserState.cid,
          did: deviceId,
          eid: uuidv4(),
          t: Date.now(),
          p: {
            type: "FirstActivity",
          },
          type: "dp",
          v: 3,
        }),
      };

      const { Payload } = await lambda.invoke(params).promise();
      const result = JSON.parse(Payload);

      if (result.errorType) {
        this.context.setUserStateStatus({
          success: "",
          warning: "",
          error: `${result.errorType} ${result.errorMessage}`,
        });
        return;
      }

      if (result.body.includes("RealtimeFeedback has no response for you:")) {
        this.context.setUserStateStatus({
          success: "",
          error: "",
          warning: `${result.body}`,
        });
      } else {
        this.context.setUserStateStatus({
          warning: "",
          error: "",
          success: `${result.body}`,
        });
      }
    } catch (e) {
      this.context.setUserStateStatus({
        success: "",
        warning: "",
        error: `${e.message}`,
      });
    }
  };

  _timeTravel = async () => {
    const { activeDay } = this.context;

    await this._fetchState();
    this._handleActiveDayChange(null, `${activeDay + 1}`);
    await this._saveUpdate({ state: null });

    await this.resendFirstActivity();

    await new Promise((r) => setTimeout(r, 5000));
    await this._fetchState();
  };

  handleOnBoardingReset = async () => {
    try {
      debugger;
      const tempUserState = JSON.parse(this.context.userState);
      delete tempUserState.onBoardingPassedOn;
      delete tempUserState.onBoardingSkippedOn;
      delete tempUserState.preferredInteractionPeriod;
      delete tempUserState.baseline?.pronoun;
      this.context.setUserState(JSON.stringify(tempUserState, null, 2));
    } catch (e) {
      this.context.setUserStateStatus({ warning: `${e.message}` });
    }
  };

  render() {
    const {
      deviceId,
      activeDay,
      extraNeeds,
      userJourneys,
      journeys,
      userStateEnv,
      locale,
      userState,
      initialUserState,
      userStateStatus,
      featureFlags,
    } = this.context;
    const { success, error, warning } = userStateStatus;

    let userStateJourneys = [];
    let features = {};
    try {
      const state = JSON.parse(userState);
      features = { ...state.features, ...state.extraFeatures } || {};
      userStateJourneys = state.currentJourney ? [state.currentJourney] : [];
    } catch (_) {}

    return (
      <Stack tokens={{ childrenGap: 15 }}>
        <Text variant="large">
          Update a Device State{" "}
          {deviceId ? (
            <span>
              for <code>{deviceId}</code>
            </span>
          ) : (
            ""
          )}
        </Text>

        <Stack
          horizontal
          tokens={{ childrenGap: 15 }}
          style={{ alignItems: "flex-end" }}
        >
          <Dropdown
            required
            label="Target Account"
            placeholder="Select the Environment"
            onChange={this._handleEnvironmentChoice}
            selectedKey={userStateEnv}
            options={accounts}
          />

          <DeviceIdSelect
            onChange={this._handleDeviceIdChange}
            environment={userStateEnv}
            fetchedDid={deviceId}
            userStateEnv={userStateEnv}
          />

          <DefaultButton
            iconProps={{ iconName: "CloudImportExport" }}
            onClick={this._fetchState}
            text="Load"
          />

          <PrimaryButton
            style={{ backgroundColor: "#cb0000" }}
            iconProps={{ iconName: "BoxCheckmarkSolid" }}
            onClick={this._saveUpdate}
            text="Save"
          />

          <Stack>
            {success && (
              <MessageBar messageBarType={MessageBarType.success} isMultiline>
                {success}
              </MessageBar>
            )}

            {error && (
              <MessageBar messageBarType={MessageBarType.error} isMultiline>
                {error}
              </MessageBar>
            )}

            {warning && (
              <MessageBar messageBarType={MessageBarType.warning} isMultiline>
                {warning}
              </MessageBar>
            )}
          </Stack>
        </Stack>

        {initialUserState && (
          <Stack tokens={{ childrenGap: 15 }}>
            <Stack
              horizontal
              tokens={{ childrenGap: 15 }}
              style={{ alignItems: "flex-end" }}
            >
              <TextField
                label="Active day"
                value={activeDay}
                onChange={this._handleActiveDayChange}
                style={{ width: 150, fontFamily: "monospace" }}
                type="number"
                min="1"
              />
              <Dropdown
                label="Locale"
                placeholder="Select locale..."
                onChange={this._handleLocaleChange}
                selectedKey={locale}
                options={locales}
                styles={{ dropdown: { width: 150 } }}
              />
              <ComboBox
                placeholder="Current journey"
                label="Select current journey"
                onChange={this._handleUJChange}
                autoComplete="on"
                persistMenu
                options={
                  journeys
                    ? [
                        {
                          key: "customJourneys",
                          text: "Custom UJs from content dashboard",
                          itemType: DropdownMenuItemType.Header,
                        },
                        ...Object.values(journeys)
                          .filter(({ cid }) => cid)
                          .map((journey) => {
                            return {
                              selected: userJourneys?.[0] === journey.journeyId,
                              key: journey.journeyId,
                              text: `${journey.journeyUserDescription} (${
                                journey.cid
                              })${getJourneyFromCompanyTemplateLabel({
                                userJourneys,
                                userStateJourneys,
                                journey,
                              })}`,
                            };
                          }),

                        {
                          key: "divider",
                          text: "-",
                          itemType: DropdownMenuItemType.Divider,
                        },
                        {
                          key: "spredsheetJourneys",
                          text: "Spreadsheet UJs",
                          itemType: DropdownMenuItemType.Header,
                        },
                        ...Object.values(journeys)
                          .filter(({ cid }) => !cid)
                          .map((journey) => {
                            return {
                              selected: userJourneys?.[0] === journey.journeyId,
                              key: journey.journeyId,
                              text: `${
                                journey.journeyId
                              }${getJourneyFromCompanyTemplateLabel({
                                userJourneys,
                                userStateJourneys,
                                journey,
                              })}`,
                            };
                          }),
                      ]
                    : []
                }
                style={{ width: 250 }}
                dropdownWidth={250}
              />
              <TextField
                label="Extra needs"
                placeholder="Enter extra needs, separated by `,`"
                value={extraNeeds.join(",")}
                onChange={this._handleExtraNeedsChange}
                style={{ width: 225, fontFamily: "monospace" }}
              />
              <Dropdown
                placeholder="Features"
                label="Select extra features"
                selectedKeys={Object.entries(features)
                  .filter(([_, isEnabled]) => isEnabled)
                  .map(([storyId]) => storyId)}
                multiSelect
                options={featureFlags.map(({ storyId, notes }) => ({
                  key: storyId,
                  text: storyId,
                  title: notes,
                }))}
                styles={{ dropdown: { width: 250 } }}
                onChange={this._handleFeaturesChange}
              />
            </Stack>
            <Stack
              horizontal
              tokens={{ childrenGap: 15 }}
              style={{ alignItems: "flex-end" }}
            >
              <DefaultButton
                text="Clear semantics history"
                split
                splitButtonAriaLabel="See options"
                aria-roledescription="split button"
                iconProps={{ iconName: "WorkItem" }}
                menuProps={{
                  items: [
                    {
                      key: "clearLast",
                      text: "Clear last item",
                      iconProps: { iconName: "WorkItemBug" },
                      onClick: this._clearLastItemInSemanticsHistory,
                    },
                  ],
                }}
                onClick={this._clearSemanticsHistory}
              />

              <DefaultButton
                iconProps={{ iconName: "Reply" }}
                text="Receive S_P999"
                onClick={this.resendFirstActivity}
              />

              <DefaultButton
                iconProps={{ iconName: "Clock" }}
                text="Time travel +1d"
                onClick={this._timeTravel}
              />

              <DefaultButton
                iconProps={{ iconName: "RevToggleKey" }}
                text="Reset onboarding"
                onClick={this.handleOnBoardingReset}
              />
            </Stack>
          </Stack>
        )}

        {initialUserState && userJourneys && Number.isFinite(activeDay) && (
          <MessageBar>
            <span>
              User is active for: <b>{formatDays(activeDay - 1 || 0)}</b>
            </span>
            <br />
            <HabitsDetails
              env={userStateEnv}
              userJourneys={
                initialUserState.currentJourney
                  ? [initialUserState.currentJourney]
                  : userJourneys
              }
              journeys={journeys}
              activeDay={activeDay}
            />
          </MessageBar>
        )}

        <TextField
          required
          label="User State"
          placeholder="Enter a DeviceId..."
          value={userState}
          onChange={this._handleUserStateChange}
          style={{ width: 250, fontFamily: "monospace" }}
          multiline
          autoAdjustHeight
        />
      </Stack>
    );
  }
}

UserStateEditor.contextType = AppContext;
