const getFieldProps = (formik, { name, disabled }) => ({
  name,
  value: formik.values[name],
  onBlur: formik.handleBlur,
  onChange: formik.handleChange,
  disabled: formik.isSubmitting || disabled,
  errorMessage: (formik.touched[name] || formik.is) && formik.errors[name],
});

export default getFieldProps;
