// @flow
import React, { memo, useCallback } from "react";
import { GroupedList, SelectionMode } from "@fluentui/react";
import type { Need, UserJourneys, Env } from "@dtttd/types/hi";

import getAcceptedEnvironments from "../lib/getAcceptedEnvironments";

type Group = {|
  count: number,
  key: string,
  name: string,
  startIndex: number,
  isCollapsed: boolean,
|};

type Props = {|
  +userJourneys: UserJourneys,
  +journeys: UserJourneys,
  +env: Env,
  +activeDay: number,
|};

const HabitsDetails = ({
  userJourneys,
  journeys,
  env,
  activeDay,
}: Props = {}) => {
  const onRenderCell = useCallback(
    (nestingDepth: number, item: Need) => <span>{item.needName}</span>,
    []
  );

  if (!Array.isArray(userJourneys)) {
    return null;
  }

  if (!journeys) {
    return null;
  }

  const acceptedEnvironments = getAcceptedEnvironments(env);

  const preparedUserJourneys = userJourneys
    .map((uj) => {
      const journey = journeys[uj];

      return (
        journey && {
          ...journey,
          offsetDays: 0,
        }
      );
    })
    .filter(
      (journey) =>
        Boolean(journey) && acceptedEnvironments.includes(journey.env)
    )
    .map((journey) => ({
      ...journey,
      needs: journey.needs.filter(
        ({ startDay, endDay }) =>
          (activeDay >= startDay + journey.offsetDays) &
          (!endDay || activeDay <= endDay + journey.offsetDays)
      ),
    }))
    .filter(({ needs }) => needs.length > 0);

  const { groups }: { groups: Array<Group> } = preparedUserJourneys.reduce(
    (total, journey) => {
      total.groups.push({
        count: journey.needs.length,
        key: journey.journeyName,
        name: journey.journeyName,
        isCollapsed: true,
        startIndex: total.startIndex,
      });
      total.startIndex += journey.needs.length;

      return total;
    },
    {
      groups: [],
      startIndex: 0,
    }
  );

  return (
    <GroupedList
      items={preparedUserJourneys.map(({ needs }) => needs).flat()}
      onRenderCell={onRenderCell}
      selectionMode={SelectionMode.none}
      groups={groups}
      compact
    />
  );
};

export default memo<Props>(HabitsDetails);
