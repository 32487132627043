// @flow

import React, { PureComponent } from "react";
import { Stack } from "@fluentui/react";
import styled from "styled-components";
import AWS from "aws-sdk";
import { Auth } from "@aws-amplify/auth";

import moment from "moment";

import type { BuildInfo } from "../types";

const LogContent = styled.table`
  white-space: pre-wrap;
  padding: 10px;
  border: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  color: #aaa;
  font-family: monospace !important;
  overflow: scroll;

  tr,
  td {
    border: 0;
  }

  td {
    padding: 1px 2px;
    font-weight: lighter;
  }

  tr.error > td {
    color: #a00;
    font-weight: bold;
  }

  tr.success > td {
    color: #080;
    font-weight: bold;
  }

  tr.container > td {
    opacity: 0.75;
  }

  tr.cdk > td {
    padding-top: 3px;
    color: white;
  }
`;

type Props = {|
  +buildInfo: BuildInfo,
|};

type State = {|
  +logLines: [],
  +error: string | false,
|};

export default class LogsPanel extends PureComponent<Props, State> {
  _refreshInterval: IntervalID;
  state: State = {
    logLines: [],
    error: false,
  };

  async componentDidMount() {
    await this.fetchDetails();
    this._refreshInterval = setInterval(() => this.fetchDetails(), 5 * 1000);
  }

  componentWillUnmount() {
    clearInterval(this._refreshInterval);
  }

  async fetchDetails() {
    this.setState((state) => ({
      ...state,
    }));

    const { buildInfo } = this.props;
    const { groupName, streamName } = buildInfo.logs;

    const creds = Auth.essentialCredentials(await Auth.currentCredentials());
    const cwl = new AWS.CloudWatchLogs({ ...creds });

    try {
      const logResults = await cwl
        .getLogEvents({
          logGroupName: groupName,
          logStreamName: streamName,
        })
        .promise();

      const logLines = logResults.events;
      this.setState((state) => ({
        ...state,
        logLines,
      }));
    } catch (e) {
      console.error("Cannot fetch builds list", e);
      this.setState(() => ({
        error: `Cannot fetch build details: "${e.message}"`,
      }));
    }
  }

  render() {
    const { logLines } = this.state;

    const getClassName = (message) => {
      if (
        message.includes("FAILED") ||
        message.toLowerCase().includes("error")
      ) {
        return "error";
      }
      if (message.includes("SUCCEE")) {
        return "success";
      }
      if (message.includes("[Container]")) {
        return "container";
      }
      if (message.includes("| AWS::")) {
        return "cdk";
      }
      return "";
    };

    const stringify = ({ timestamp, message }, i) => (
      <tr key={timestamp + i} className={getClassName(message)}>
        <td>{moment(timestamp).format("HH:mm:ss.SSSS")}</td>
        <td>{message}</td>
      </tr>
    );

    return (
      <Stack tokens={{ maxWidth: "100%" }}>
        <LogContent>
          <tbody>{logLines.map(stringify)}</tbody>
        </LogContent>
      </Stack>
    );
  }
}
