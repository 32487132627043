// @flow

import React, { useCallback, useState, memo, useEffect, useMemo } from "react";
import { ComboBox } from "@fluentui/react";
import { Auth } from "@aws-amplify/auth";
import AWS from "aws-sdk";
import debounce from "lodash.debounce";

import accounts from "../../accounts";

type Props = {
  isRequired: boolean,
  onChange?: (string) => void,
  environment: string,
  fetchedDid: string,
  userStateEnv: string,
};

export const fetchDeviceIds = async ({
  searchValue,
  userMetaBucketName,
}: {
  searchValue: string,
  userMetaBucketName: string,
}) => {
  const creds = Auth.essentialCredentials(await Auth.currentCredentials());
  const s3 = new AWS.S3({ ...creds });

  const keysList = [];
  let isAllKeysListed = false;
  let nextContinuationToken;

  while (!isAllKeysListed) {
    const response: {
      Contents: Array<{ Key: string }>,
      IsTruncated: boolean,
      NextContinuationToken?: string,
      // eslint-disable-next-line no-await-in-loop
    } = await s3
      .listObjectsV2({
        Bucket: userMetaBucketName,
        Prefix: `userstate/${searchValue}`,
        Delimiter: "/",
        ...(nextContinuationToken && {
          ContinuationToken: nextContinuationToken,
        }),
      })
      .promise();

    const { Contents, IsTruncated, NextContinuationToken } = response;
    keysList.push(
      ...Contents.map(({ Key }) => Key.replace(/(userstate\/)|(\.json)/g, ""))
    );

    nextContinuationToken = NextContinuationToken;
    isAllKeysListed = !IsTruncated;
  }

  return keysList;
};

const DeviceIdSelect = ({
  isRequired = true,
  onChange,
  environment,
  fetchedDid,
  userStateEnv,
}: Props) => {
  const [deviceId, setDeviceId] = useState("");
  const [deviceIds, setDeviceIds] = useState([]);

  const getHistoryForEnv = useCallback(() => {
    const currentHistory = JSON.parse(localStorage.getItem("history") || "{}");
    return (currentHistory[userStateEnv] || []).map((id) => ({
      key: id,
      text: id,
    }));
  }, [userStateEnv]);

  const userMetaBucketName = useMemo(
    () => accounts.find(({ key }) => key === environment)?.userMetaBucketName,
    [environment]
  );

  const handleChange = useCallback(
    async (
      e: SyntheticEvent<HTMLInputElement>,
      option: {| key: string, text: string |},
      index: number,
      value: string
    ) => {
      const deviceId = (option?.key || value)
        ?.trim()
        .toLowerCase()
        .replace(/[^a-f0-9-]+/, "");

      setDeviceId(deviceId);
      if (onChange) {
        onChange(deviceId);
      }
    },
    [onChange]
  );

  const fetchDeviceIdsDebounced = useCallback(
    debounce(
      (...args) =>
        fetchDeviceIds(...args).then((deviceIds) => {
          setDeviceIds(deviceIds);
        }),
      500
    ),
    [setDeviceIds]
  );

  const handleInput = useCallback(
    async (
      option: {| key: string, text: string |},
      index: number,
      value: string
    ) => {
      if (typeof value !== "string" || !userMetaBucketName) {
        return;
      }

      if (value?.length < 3) {
        setDeviceIds([]);
        return;
      }
      fetchDeviceIdsDebounced({
        searchValue: value,
        userMetaBucketName,
      });
    },
    [userMetaBucketName, fetchDeviceIdsDebounced]
  );

  useEffect(() => {
    setDeviceId(deviceId);
  }, [deviceId]);

  return (
    <ComboBox
      required={isRequired}
      autoComplete="on"
      allowFreeform
      label="Device Id"
      placeholder="Enter device id..."
      text={fetchedDid || deviceId}
      onChange={handleChange}
      onPendingValueChanged={handleInput}
      style={{ width: 350, fontFamily: "monospace" }}
      options={
        deviceIds.length > 0
          ? deviceIds.map((id) => ({ key: id, text: id }))
          : getHistoryForEnv()
      }
    />
  );
};

export default memo<Props>(DeviceIdSelect);
