// @flow

import React, { useMemo, useCallback, useState } from "react";
import {
  Dropdown,
  Stack,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  TextField,
  MessageBarType,
  MessageBar,
} from "@fluentui/react";
import { useFormik } from "formik";
import * as Yup from "yup";

import { useAppContext } from "../../context/AppContext";
import getFieldProps from "../../lib/getFieldProps";
import { Auth } from "@aws-amplify/auth";
import AWS from "aws-sdk";

const dropdownStyles = { dropdown: { width: 300 } };

type Props = {
  +accounts: {| [environment: string]: string |},
};

const validationSchema = Yup.object({
  env: Yup.string().required(),
  deviceIds: Yup.string()
    .min(36)
    .required()
    .test(
      "isValidUUIDV4",
      // eslint-disable-next-line
      "${path} contains invalid UUID V4 device ids",
      (value, context) => {
        const errors = (value || "")
          .split(/,|\n/)
          .map((value) => value.trim())
          .filter(Boolean)
          .filter(
            (value) =>
              !value.match(
                /^[0-9A-F]{8}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{12}$/i
              )
          );

        return (
          errors.length === 0 ||
          context.createError({
            message: `Invalid UUID V4 device ids: ${errors.join(", ")}.`,
          })
        );
      }
    ),
  companyId: Yup.string().required(),
  journeyId: Yup.string().required(),
});

const localeMap = {
  "en-GB": "English",
  "fr-FR": "French",
  "it-IT": "Italian",
};

const locales = Object.keys(localeMap).map((k) => {
  return { key: k, text: localeMap[k] };
});

const Activation = ({ accounts }: Props) => {
  const { userStateEnv, setUserStateEnv } = useAppContext();
  const [response, setResponse] = useState();
  const [error, setError] = useState();
  const formik = useFormik({
    initialValues: {
      env: userStateEnv,
      deviceIds: "",
      companyId: "",
      journeyId: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      setError();
      setResponse();
      const creds = Auth.essentialCredentials(await Auth.currentCredentials());
      const lambda = new AWS.Lambda({ ...creds });

      const deviceIds = values.deviceIds
        .split(/,|\n/)
        .map((value) => value.trim())
        .filter(Boolean);

      debugger;

      const invokeOpts = {
        Payload: JSON.stringify({
          deviceIds,
          companyId: values.companyId,
          journeyId: values.journeyId,
          locale: values.locale,
        }),
        InvocationType: "RequestResponse",
      };

      try {
        const { Payload } = await lambda
          .invoke({
            ...invokeOpts,
            FunctionName: `arn:aws:lambda:eu-west-1:${
              accounts[values.env]
            }:function:users-activation:current`,
          })
          .promise();

        const response = JSON.parse(Payload);

        if (response.errorMessage) {
          setError(response.errorMessage);
          return;
        }

        setResponse(response);
      } catch (e) {
        setError(e.message);
      }
    },
  });

  const handleAccountChange = useCallback(
    (e, { key }) => {
      formik.setFieldValue("env", key);
      formik.setFieldTouched("env", true);
      setUserStateEnv(key);
    },
    [formik, setUserStateEnv]
  );

  const handleLocaleChange = useCallback(
    (e, { key }) => {
      formik.setFieldValue("locale", key);
      formik.setFieldTouched("locale`", key);
    },
    [formik]
  );

  const environmentOptions = useMemo<[{ key: string, text: string }]>(
    () =>
      Object.keys(accounts).map((key) => ({
        key,
        text: key,
      })),
    [accounts]
  );

  return (
    <Stack tokens={{ childrenGap: 15 }}>
      <Stack
        horizontal
        tokens={{ childrenGap: 12 }}
        style={{ alignItems: "flex-end" }}
      >
        <Dropdown
          required
          label="Environment"
          placeholder="Select the Environment"
          options={environmentOptions}
          selectedKey={formik.values.env}
          {...getFieldProps(formik, { name: "env" })}
          onChange={handleAccountChange}
          styles={dropdownStyles}
        />
      </Stack>
      <Stack horizontal>
        <TextField
          required
          label="Device IDs. List all device ids impacted, separated by comma or new line"
          multiline
          resizable={false}
          rows={10}
          {...getFieldProps(formik, { name: "deviceIds" })}
        />
      </Stack>
      <Stack horizontal>
        <TextField
          required
          label="Company ID"
          {...getFieldProps(formik, { name: "companyId" })}
        />
      </Stack>
      <Stack horizontal>
        <TextField
          required
          label="Coaching plan"
          {...getFieldProps(formik, { name: "journeyId" })}
        />
      </Stack>
      <Stack horizontal>
        <Dropdown
          label="Locale"
          placeholder="Select locale..."
          {...getFieldProps(formik, { name: "locale" })}
          onChange={handleLocaleChange}
          selectedKey={formik.values.locale}
          options={locales}
          styles={dropdownStyles}
        />
      </Stack>
      <Stack horizontal tokens={{ childrenGap: 12 }}>
        <PrimaryButton
          iconProps={{ iconName: "ActivateOrders" }}
          text="Activate HI"
          onClick={formik.submitForm}
          disabled={formik.isSubmitting}
        />
        {formik.isSubmitting && <Spinner size={SpinnerSize.large} />}
      </Stack>
      {response && (
        <MessageBar messageBarType={MessageBarType.info} isMultiline>
          Success: {response.success} devices
          <br />
          Error: {response.errors.length} devices
          {response.errors.length > 0 && (
            <>
              <br />
              {response.errors.join(", ")}
            </>
          )}
          <br />
          Not found: {response.notFound.length} devices
          {response.notFound.length > 0 && (
            <>
              <br />
              {response.notFound.join(", ")}
            </>
          )}
        </MessageBar>
      )}
      {error && (
        <MessageBar messageBarType={MessageBarType.error} isMultiline>
          {error}
        </MessageBar>
      )}
    </Stack>
  );
};

export default Activation;
