// @flow

import AWS from "aws-sdk";
import { Auth } from "@aws-amplify/auth";
import React, { PureComponent } from "react";
import {
  Stack,
  ShimmeredDetailsList,
  SelectionMode,
  Text,
  Link,
  Icon,
  Spinner,
  SpinnerSize,
} from "@fluentui/react";
import moment from "moment";

declare type Status = "Succeeded" | "Failed" | "InProgress" | "Unknown";
declare type State = { items: string[] };

const AWS_REGION = "eu-west-1";
const TARGET_ROLE_ARN =
  "arn:aws:iam::581074713875:role/TeamDashboardListBuilds";
const BUILDS_NUMBER_LIMIT = 50;

const getCodeBuildClient = async (): Promise<AWS.CodeBuild> => {
  const devCreds = Auth.essentialCredentials(await Auth.currentCredentials());
  const devSts = new AWS.STS({
    ...devCreds,
    region: AWS_REGION,
  });

  const targetCredentials = await devSts
    .assumeRole({
      RoleArn: TARGET_ROLE_ARN,
      RoleSessionName: "team-deployments-session",
    })
    .promise();

  const codeBuildClient = new AWS.CodeBuild({
    accessKeyId: targetCredentials.Credentials.AccessKeyId,
    secretAccessKey: targetCredentials.Credentials.SecretAccessKey,
    sessionToken: targetCredentials.Credentials.SessionToken,
    region: AWS_REGION,
  });
  return codeBuildClient;
};

export default class ProdDeploymentsList extends PureComponent<Props, State> {
  state: State;
  _interval: Interval;

  constructor(props: Props) {
    super(props);

    this.state = {
      items: [],
    };
  }

  async componentDidMount() {
    await this.refreshData();
  }

  componentWillUnmount() {
    clearInterval(this._interval);
  }

  async refreshData() {
    const cb = await getCodeBuildClient();

    const buildIds = (
      await cb
        .listBuildsForProject({ projectName: "production-deployer-1" })
        .promise()
    ).ids.slice(0, BUILDS_NUMBER_LIMIT);

    const getBuildResponse = await cb
      .batchGetBuilds({ ids: buildIds })
      .promise();

    const getEnvValue = (build, name) =>
      build.environment.environmentVariables.find((v) => v.name === name)
        ?.value;

    const getBuildFields = (build) => {
      return {
        buildNumber: build.buildNumber,
        deployDate: build.endTime,
        status: build.buildStatus,
        name: getEnvValue(build, "TARGET_REPO"),
        storyWorkspace: getEnvValue(build, "WORKSPACE"),
        storyId: getEnvValue(build, "STORY_ID"),
        sourceVersion: getEnvValue(build, "COMMIT_SHA"),
        mergeDate: getEnvValue(build, "COMMIT_MERGED_AT"),
        targetAccount:
          "..." + getEnvValue(build, "TARGET_ACCOUNT_ID")?.substr(-4),
        logsDeepLink: build.logs.deepLink,
      };
    };

    const buildInfo = getBuildResponse.builds.map(getBuildFields);
    console.log({ buildInfo });
    this.setState((state) => ({
      ...state,
      items: buildInfo,
    }));
  }

  _getStatusIcon = (status: Status) => {
    const iconStyle = { height: 30, width: 30, display: "inline-flex" };
    if (status === "FAILED") {
      return (
        <Icon
          iconName="BoxMultiplySolid"
          style={{ ...iconStyle, color: "red" }}
        />
      );
    }
    if (status === "SUCCEEDED") {
      return (
        <Icon
          iconName="BoxCheckmarkSolid"
          style={{ ...iconStyle, color: "green" }}
        />
      );
    }
    if (status === "INPROGRESS") {
      return <Spinner style={iconStyle} size={SpinnerSize.medium} />;
    }
    if (status === "UNKNOWN") {
      return (
        <Icon iconName="UnknownSolid" style={{ ...iconStyle, color: "gray" }} />
      );
    }
  };

  _renderItemColumn = (item: $FlowFixMe, index: number, column: $FlowFixMe) => {
    switch (column.key) {
      case "name":
        return (
          <Stack horizontal>
            <Text style={{ fontWeight: "bold" }}>
              {item.name.replace("dtttd/", "")}
            </Text>
          </Stack>
        );

      case "mergeDate":
        return (
          <Stack horizontal>
            <Text>{moment(item.mergeDate).format("llll")}</Text>
          </Stack>
        );

      case "storyId": {
        return (
          <Stack>
            <Text>
              {item.storyWorkspace}{" "}
              <span style={{ fontWeight: "bold" }}>ch{item.storyId}</span>
            </Text>
          </Stack>
        );
      }

      case "status": {
        return (
          <Stack horizontal>
            <Text>
              {this._getStatusIcon(item.status)}

              <Link target="_blank" href={item.logsDeepLink}>
                {item.buildNumber}
              </Link>
            </Text>
          </Stack>
        );
      }

      case "sourceVersion": {
        return (
          <Stack horizontal>
            <Link
              target="_blank"
              href={`https://github.com/${item.name}/commit/${item.sourceVersion}`}
            >
              ...{item.sourceVersion?.substr(-10)}
            </Link>
          </Stack>
        );
      }

      case "deployDate": {
        return (
          <Stack horizontal>
            <Text>{moment(item.deployDate).format("llll")}</Text>
          </Stack>
        );
      }

      default:
        return (
          <Stack horizontal>
            <Text>{item[column.key]}</Text>
          </Stack>
        );
    }
  };

  _getColumns = () => {
    return [
      {
        key: "name",
        name: "Microservice",
        fieldName: "name",
        minWidth: 130,
        maxWidth: 130,
        isResizable: true,
      },
      {
        key: "storyId",
        name: "Story ID",
        minWidth: 130,
        maxWidth: 130,
        isResizable: true,
      },
      {
        key: "mergeDate",
        name: "Git Merge Date",
        minWidth: 170,
        maxWidth: 170,
        isResizable: true,
      },
      {
        key: "deployDate",
        name: "Deploy Date",
        fieldName: "deployDate",
        minWidth: 170,
        maxWidth: 170,
        isResizable: true,
      },
      {
        key: "sourceVersion",
        name: "Git Hash",
        minWidth: 75,
        maxWidth: 75,
        isResizable: true,
      },
      {
        key: "targetAccount",
        name: "Target Account",
        minWidth: 75,
        maxWidth: 75,
        isResizable: true,
      },
      {
        key: "status",
        name: "Status (build #)",
        minWidth: 75,
        maxWidth: 75,
        isResizable: true,
      },
    ];
  };

  render() {
    const { items } = this.state;

    return (
      <Stack tokens={{ childrenGap: 20 }}>
        <Stack grow={1}>
          <ShimmeredDetailsList
            items={items}
            setKey="ProdDeploymentsList"
            selectionMode={SelectionMode.none}
            columns={this._getColumns()}
            onRenderItemColumn={this._renderItemColumn}
            enableShimmer={!items || items.length === 0}
          />
        </Stack>
      </Stack>
    );
  }
}
