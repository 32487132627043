// @flow
const variants = {
  one: "day",
  other: "days",
};

const formatDays = (num: number): string => {
  if (Intl.PluralRules) {
    const rules = new Intl.PluralRules("en-US");
    const rule = rules.select(num);
    const variant = variants[String(rule)];
    return `${num} ${variant}`;
  } else {
    return `${num} days`;
  }
};

export default formatDays;
