// @flow

import React, { PureComponent } from "react";
import {
  SelectionMode,
  Link,
  Text,
  ShimmeredDetailsList,
  CommandBar,
  Selection,
  Panel,
  PanelType,
} from "@fluentui/react";
import styled, { type StyledComponent } from "styled-components";

import ExecuteDeployPanel from "./ExecuteDeployPanel";
import BuildDetails from "./BuildDetails";

const Container: StyledComponent<{||}, {||}, HTMLDivElement> = styled.div`
  display: flex;
  flex-direction: column;
`;

type Props = {|
  +accounts: {| [accountId: string]: string |},
  +codebuildProjects: Array<string>,
  +microservices: Array<$FlowFixMe>,
  +deployments: Array<$FlowFixMe>,
|};

type State = {|
  +executeDeployPanelOpened: boolean,
  +deployRequestParams: {|
    microserviceName: string,
    developerName: string,
    branchName: string,
  |},
  +showBuilds: boolean,
  +items: Array<$FlowFixMe>,
|};

export default class AccountsList extends PureComponent<Props, State> {
  _selection: Selection;
  _microserviceSelection: Selection;

  state = {
    executeDeployPanelOpened: false,
    deployRequestParams: {
      microserviceName: "",
      developerName: "",
      branchName: "",
    },
    showBuilds: false,
    items: [],
  };

  static getDerivedStateFromProps(props: Props, state: State) {
    const { deployments, accounts } = props;

    const items = [
      ...deployments.map((deployment) => ({
        ...deployment,
        developer: Object.keys(accounts).find(
          (developerName) => accounts[developerName] === deployment.accountId
        ),
      })),
    ];

    return {
      ...state,
      items,
    };
  }

  constructor(props: Props) {
    super(props);

    this._selection = new Selection({
      onSelectionChanged: () => {
        this._microserviceSelection.setAllSelected(false);
        this.forceUpdate();
      },
    });

    this._microserviceSelection = new Selection({
      onSelectionChanged: () => {
        this._selection.setAllSelected(false);
        this.forceUpdate();
      },
    });
  }

  _renderItemColumn = (item: $FlowFixMe, index: number, column: $FlowFixMe) => {
    const fieldContent = item[column.fieldName];

    switch (column.key) {
      case "storyid":
        return (
          <Link
            data-selection-disabled={true}
            href={`https://app.clubhouse.io/dtttd/story/${item.storyId}`}
            target="_blank"
          >
            {fieldContent}
          </Link>
        );

      case "branch":
        return (
          <Link
            data-selection-disabled={true}
            href={`https://github.com/dtttd/${item.name}/commit/${item.commitHash}`}
            target="_blank"
          >
            {fieldContent}
          </Link>
        );

      default:
        return <span>{fieldContent}</span>;
    }
  };

  _getColumns = () => {
    return [
      {
        key: "name",
        name: "Microservice",
        fieldName: "name",
        minWidth: 200,
        maxWidth: 200,
        isResizable: false,
      },
      {
        key: "developer",
        name: "Developer",
        fieldName: "developer",
        minWidth: 200,
        maxWidth: 200,
        isResizable: false,
      },
      {
        key: "storyid",
        name: "Deployed Story Id",
        fieldName: "storyId",
        minWidth: 150,
        maxWidth: 150,
        isResizable: false,
      },
      {
        key: "branch",
        name: "Deployed Branch",
        fieldName: "branchName",
        minWidth: 200,
        isResizable: true,
      },
    ];
  };

  _getMicroserviceColumns = () => {
    return [
      {
        key: "name",
        name: "Microservice",
        fieldName: "name",
        minWidth: 200,
        maxWidth: 200,
        isResizable: false,
      },
    ];
  };

  hideDeploymentPanel = () => {
    this.setState((state) => ({
      executeDeployPanelOpened: false,
      deployRequestParams: {
        microserviceName: "",
        developerName: "",
        branchName: "",
      },
    }));
  };

  showDeploymentPanel = () => {
    const item =
      this._selection.getSelection()[0] ||
      this._microserviceSelection.getSelection()[0]; // only one item is supported

    if (!item) {
      alert("Please select a Story or Microservice to be deployed.");
      return;
    }

    const {
      name: microserviceName,
      developer: developerName,
      branchName,
    } = item;

    this.setState((state) => ({
      executeDeployPanelOpened: true,
      deployRequestParams: {
        microserviceName,
        developerName,
        branchName,
      },
    }));
  };

  render() {
    const { accounts, microservices } = this.props;
    const {
      executeDeployPanelOpened,
      deployRequestParams,
      items,
      showBuilds,
    } = this.state;

    const deploymentsList = items.sort((i, ii) =>
      `${i.name}${i.developer}` > `${ii.name}${ii.developer}` ? 1 : -1
    );

    const commands = [
      {
        key: "deploy",
        text: "Deploy to...",
        iconProps: { iconName: "Upload" },
        onClick: this.showDeploymentPanel,
      },
      {
        key: "showBuilds",
        text: "Builds",
        iconProps: { iconName: "GroupedDescending" },
        onClick: () => this.setState({ showBuilds: true }),
      },
    ];

    const selectedDeployment =
      this._selection.getSelection()[0] ||
      this._microserviceSelection.getSelection()[0];

    const getSelectedProjectName = () =>
      selectedDeployment.developer
        ? `devbuild-${selectedDeployment.name}-${selectedDeployment.developer}`
        : `pipeline-deployer-${selectedDeployment.name}`;

    return (
      <Container>
        <CommandBar items={commands} />

        <Text variant="large">Developers Deployments</Text>
        <ShimmeredDetailsList
          items={deploymentsList}
          setKey="storyId"
          selectionPreservedOnEmptyClick={true}
          selection={this._selection}
          selectionMode={SelectionMode.single}
          columns={this._getColumns()}
          onRenderItemColumn={this._renderItemColumn}
          enableShimmer={items.length === 0}
        />

        <Text variant="large">Master Deployments</Text>
        <ShimmeredDetailsList
          items={microservices}
          setKey="name"
          selectionPreservedOnEmptyClick={true}
          selection={this._microserviceSelection}
          selectionMode={SelectionMode.single}
          columns={this._getMicroserviceColumns()}
          // onRenderItemColumn={this._renderMicroserviceItemColumn}
          enableShimmer={!microservices || microservices.length === 0}
        />

        {selectedDeployment && (
          <Panel
            isOpen={showBuilds}
            type={PanelType.large}
            onDismiss={() => this.setState({ showBuilds: false })}
            isLightDismiss
            headerText="Deployments List"
          >
            <BuildDetails
              projectName={getSelectedProjectName()}
              accounts={accounts}
            />
          </Panel>
        )}

        {executeDeployPanelOpened && (
          <ExecuteDeployPanel
            isOpen
            onClose={this.hideDeploymentPanel}
            accounts={accounts}
            {...deployRequestParams}
          />
        )}
      </Container>
    );
  }
}
