// @flow
import React, { PureComponent } from "react";
import AWS from "aws-sdk";
import { Auth } from "@aws-amplify/auth";
import { Pivot, PivotItem } from "@fluentui/react";
import { initializeIcons } from "@uifabric/icons";

import ProdDeploymentsList from "./ProdDeploymentsList";
import PipelinesList from "./PipelinesList";
import MicroservicesDeployments from "./MicroservicesDeployments";
import UserStateEditor from "./UserStateEditor";
import CompanyStateEditor from "./CompanyStateEditor";
import ContentsPull from "./ContentsPull";
import Builds from "./Builds";
import Analytics from "./Analytics";
import Features from "./Features";
import Activation from "./Activation";
import { AppContext } from "../context/AppContext";

// Loads icons from OneDrive CDN:
// https://developer.microsoft.com/en-us/fluentui#/styles/web/icons
initializeIcons();

const MICROSERVICES_TABLE =
  process.env
    .REACT_APP_service_cd_team_pipelines_branch_deployer_services_index_table;
const DEPLOYMENTS_TABLE =
  process.env
    .REACT_APP_service_cd_team_pipelines_branch_deployer_services_deployments_table;

type Props = {||};

type State = {|
  +targetAccounts: {| [accountId: string]: string |},
  +pipelines: Array<$FlowFixMe>,
  +codebuildProjects: Array<string>,
  +microservices: Array<$FlowFixMe>,
  +deployments: Array<$FlowFixMe>,
  +pipelineStates: {| [key: string]: ?PipelineState |},
|};

export class Root extends PureComponent<Props, State> {
  state = {
    targetAccounts: {},
    microservices: [],
    deployments: [],
    codebuildProjects: [],
    pipelines: [],
    pipelineStates: {},
  };

  async componentDidMount() {
    await this.fetchData();
    if ("Notification" in window && Notification.permission === "default") {
      Notification.requestPermission();
    }
    this._fetchDetails();
    this._interval = setInterval(() => this._fetchDetails(), 20 * 1000);
    // this._fetchDataInterval = setInterval(this.fetchData, 10 * 1000);
  }

  componentWillUnmount() {
    // clearInterval(this._fetchDataInterval);
    clearInterval(this._interval);
  }

  _getPipelineName = (microservice: $FlowFixMe) => {
    return `deployment-${microservice.name}`;
  };

  _fetchDetails = async () => {
    const { microservices } = this.state;

    const creds = Auth.essentialCredentials(await Auth.currentCredentials());
    const cp = new AWS.CodePipeline({ ...creds });
    // $FlowFixMe microservices is not asyncIterable
    for await (let ms of microservices) {
      const pipelineName = this._getPipelineName(ms);

      try {
        const pipelineState = await cp
          .getPipelineState({ name: pipelineName })
          .promise();

        if (
          "Notification" in window &&
          Notification.permission === "granted" &&
          this.state.pipelineStates[ms.name]
        ) {
          pipelineState.stageStates.forEach(
            ({ stageName, latestExecution }, i) => {
              const previousStatus = this.state.pipelineStates[ms.name]
                ?.stageStates?.[i]?.latestExecution?.status;
              if (
                latestExecution?.status === "Failed" &&
                previousStatus !== "Failed"
              ) {
                new Notification(
                  `Build failed for ${ms.name} in ${stageName}`,
                  {
                    requireInteraction: true,
                  }
                );
              }
            }
          );
        }

        this.setState((state) => ({
          ...state,
          pipelineStates: {
            ...state.pipelineStates,
            [ms.name]: pipelineState,
          },
        }));
      } catch (e) {
        console.error("Cannot get state for pipeline", { pipelineName, e });
      }
    }
  };

  fetchData = async () => {
    const creds = Auth.essentialCredentials(await Auth.currentCredentials());
    const ssm = new AWS.SSM({ ...creds });
    const doc = new AWS.DynamoDB.DocumentClient({ ...creds });
    const cb = new AWS.CodeBuild({ ...creds });
    const cp = new AWS.CodePipeline({ ...creds });

    // Filters, we should probably use Tags
    const filterProject = (project) => project.startsWith("devbuild-");
    const filterPipelines = ({ name }) => name.startsWith("deployment-");

    const [
      targetAccounts,
      microservices,
      deployments,
      codebuildProjects,
      pipelines,
    ] = await Promise.all([
      JSON.parse(
        (
          await ssm
            .getParameter({ Name: "/account/deployment_targets" })
            .promise()
        ).Parameter.Value || "[]"
      ),
      (await doc.scan({ TableName: MICROSERVICES_TABLE }).promise()).Items,
      (await doc.scan({ TableName: DEPLOYMENTS_TABLE }).promise()).Items,
      (await cb.listProjects({}).promise()).projects.filter(filterProject),
      (await cp.listPipelines({}).promise()).pipelines.filter(filterPipelines),
    ]);

    const sortedMicroservices = [...microservices].sort(
      (i, ii) => i.name > ii.name
    );

    this.setState((state) => ({
      ...state,
      targetAccounts,
      microservices: sortedMicroservices,
      deployments,
      codebuildProjects,
      pipelines,
    }));
  };

  handleTabChange = (item) => {
    this.props.history.push(`/${item.props.itemKey}`);
  };

  render() {
    const {
      targetAccounts,
      microservices,
      deployments,
      codebuildProjects,
      pipelineStates,
    } = this.state;

    return (
      <Pivot
        selectedKey={this.state.tab || this.props.match.params?.tab}
        onLinkClick={this.handleTabChange}
      >
        <PivotItem headerText="Releases" itemKey="prod">
          <ProdDeploymentsList />
        </PivotItem>

        <PivotItem headerText="Pipelines" itemKey="pipelines">
          <PipelinesList
            microservices={microservices}
            pipelineStates={pipelineStates}
          />
        </PivotItem>

        {this.context.user?.groups?.includes("Developers") && (
          <PivotItem headerText="Develop" itemKey="develop">
            <MicroservicesDeployments
              microservices={microservices}
              deployments={deployments}
              accounts={targetAccounts}
              codebuildProjects={codebuildProjects}
            />
          </PivotItem>
        )}

        <PivotItem headerText="User States" itemKey="user-states">
          <UserStateEditor accounts={targetAccounts} />
        </PivotItem>

        <PivotItem headerText="Company Templates" itemKey="company-templates">
          <CompanyStateEditor accounts={targetAccounts} />
        </PivotItem>

        <PivotItem headerText="Pull Contents" itemKey="pull-contents">
          <ContentsPull accounts={targetAccounts} />
        </PivotItem>

        <PivotItem headerText="Builds" itemKey="builds">
          <Builds accounts={targetAccounts} />
        </PivotItem>

        <PivotItem headerText="Analytics" itemKey="analytics">
          <Analytics accounts={targetAccounts} />
        </PivotItem>

        <PivotItem headerText="Features" itemKey="features">
          <Features accounts={targetAccounts} />
        </PivotItem>

        <PivotItem headerText="HI Activation" itemKey="activation">
          <Activation accounts={targetAccounts} />
        </PivotItem>
      </Pivot>
    );
  }
}

Root.contextType = AppContext;

export default Root;
