// @flow

import React, { useMemo, useCallback, useState, useEffect } from "react";
import {
  ShimmeredDetailsList,
  SelectionMode,
  Dropdown,
  MessageBar,
  MessageBarType,
  Stack,
  IconButton,
} from "@fluentui/react";
import bytes from "bytes";
import orderBy from "lodash/orderBy";

import getS3 from "../../lib/getS3";
import { useAppContext } from "../../context/AppContext";

const columns = [
  {
    key: "link",
    name: "Link",
    isPadded: true,
    onRender({ link, name }) {
      return (
        <a href={link} name={name} target="_blank" rel="noopener noreferrer">
          {name}
        </a>
      );
    },
    minWidth: 250,
  },
  {
    key: "version",
    name: "Version",
    fieldName: "version",
    isPadded: true,
  },
  {
    key: "size",
    name: "Size",
    fieldName: "size",
    isPadded: true,
  },
  {
    key: "lastModified",
    name: "Last modified",
    fieldName: "lastModified",
    isPadded: true,
    minWidth: 250,
  },
];

const environmentDropdownStyles = { dropdown: { width: 300 } };

const fetchBuilds = async ({ accountId, accountName }) => {
  const s3 = await getS3();
  const bucketName = `dtttd-hi-leaf-artifacts-${accountId}-eu-west-1`;
  const PROD_DEPLOYER_ACCOUNT = "581074713875";
  const leafBuildsBucketName = `dtttd-hi-leaf-builds-${PROD_DEPLOYER_ACCOUNT}-eu-west-1`;
  const prefix = `installers/${accountName}/`;
  const [{ Contents }, { Contents: newBuilds }] = await Promise.all([
    s3
      .listObjectsV2({
        Bucket: bucketName,
      })
      .promise(),
    s3
      .listObjectsV2({
        Bucket: leafBuildsBucketName,
        Prefix: prefix,
      })
      .promise(),
  ]);

  return Promise.all(
    orderBy(
      [
        ...newBuilds.map((it) => ({
          ...it,
          BucketName: leafBuildsBucketName,
        })),
        ...Contents.map((it) => ({
          ...it,
          BucketName: bucketName,
        })),
      ],
      ["LastModified"],
      ["desc"]
    ).map(async ({ Key, LastModified, Size, BucketName }) => ({
      name: Key.replace(prefix, ""),
      lastModified: LastModified.toString(),
      size: bytes(Size),
      version: Key.match(/v[0-9.]+/)?.[0] || "-",
      link: await s3.getSignedUrlPromise("getObject", {
        Bucket: BucketName,
        Key,
        Expires: 86400,
      }),
    }))
  );
};

type Props = {
  +accounts: {| [environment: string]: string |},
};

const Builds = ({ accounts }: Props) => {
  const [items, setItems] = useState();

  const { userStateEnv, setUserStateEnv } = useAppContext();
  const [selectedAccount, setSelectedAccount] = useState<?string>(
    accounts[userStateEnv || "qa"]
  );

  useEffect(() => {
    if (selectedAccount) {
      return;
    }

    setSelectedAccount(accounts.qa);
  }, [accounts, selectedAccount]);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<?string>(null);

  const fetch = useCallback(
    ({ selectedAccount }) => {
      if (!selectedAccount) {
        return;
      }

      setIsLoading(true);
      setError(null);
      fetchBuilds({
        accountId: selectedAccount,
        accountName: userStateEnv || "qa",
      })
        .then((items) => setItems(items))
        .catch((e) => {
          setItems([]);
          setError(e.message);
        })
        .finally(() => setIsLoading(false));
    },
    [userStateEnv]
  );

  const reload = useCallback(() => fetch({ selectedAccount }), [
    selectedAccount,
    fetch,
  ]);

  useEffect(() => {
    fetch({ selectedAccount });
  }, [fetch, selectedAccount]);

  const handleAccountChange = useCallback(
    (e, { key, text }) => {
      setSelectedAccount(key);
      setUserStateEnv(text);
    },
    [setUserStateEnv, setSelectedAccount]
  );

  const environmentOptions = useMemo<[{ key: string, text: string }]>(
    () =>
      Object.entries(accounts).map(([text, key]) => ({
        key,
        text,
      })),
    [accounts]
  );

  return (
    <>
      <Stack
        horizontal
        tokens={{ childrenGap: 12 }}
        style={{ alignItems: "flex-end" }}
      >
        <Dropdown
          required
          label="Environment"
          placeholder="Select the Environment"
          options={environmentOptions}
          onChange={handleAccountChange}
          selectedKey={selectedAccount}
          styles={environmentDropdownStyles}
        />
        <IconButton
          iconProps={{ iconName: "Refresh" }}
          onClick={reload}
          title="Refresh"
        />
        {error && (
          <MessageBar messageBarType={MessageBarType.error} isMultiline>
            {error}
          </MessageBar>
        )}
      </Stack>
      {selectedAccount && (
        <ShimmeredDetailsList
          enableShimmer={isLoading}
          items={items || []}
          columns={columns}
          selectionMode={SelectionMode.none}
        />
      )}
    </>
  );
};

export default Builds;
