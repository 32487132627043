const sharedProps = {
  contentStorageBucketName: "content-storage-573434510680-eu-west-1",
};

export default [
  {
    ...sharedProps,
    key: "development",
    userMetaBucketName: "criticalstorage-usermetabucket-1ot8iw1khn2th",
    text: "Development",
  },
  {
    ...sharedProps,
    key: "qa",
    userMetaBucketName: "criticalstorage-usermetabucket-1l55cscsb0jex",
    text: "QA",
  },
  {
    ...sharedProps,
    key: "mykhailodiachuk",
    text: "mykhailodiachuk",
    userMetaBucketName: "criticalstorage-usermetabucket-qgau16n6k1mc",
  },
  {
    ...sharedProps,
    key: "denyskublitskiy",
    text: "denyskublitskiy",
    userMetaBucketName: "criticalstorage-usermetabucket-l82bljtfcei7",
  },
  {
    ...sharedProps,
    key: "w01fgang",
    text: "w01fgang",
    userMetaBucketName: "criticalstorage-usermetabucket-ztxdrfgu7kdq",
  },
  {
    ...sharedProps,
    key: "michalkwiatek",
    text: "michalkwiatek",
    userMetaBucketName: "criticalstorage-usermetabucket-1ietrhdbkh2sk",
  },
  {
    ...sharedProps,
    key: "kkicoo7",
    text: "kkicoo7",
    userMetaBucketName: "criticalstorage-usermetabucket-1o1kjt137e0pi",
  },
  {
    ...sharedProps,
    key: "simone",
    text: "simone",
    userMetaBucketName: "criticalstorage-usermetabucket-1vk49y37ewmca",
  },
];
